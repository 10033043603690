import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import RightArrow from "../../assets/icons/blog-right-arrow.svg";
import CaseStudies from "../../data/CaseStudies";
import { useNavigate } from "react-router-dom";

const Blogs = ({ blogsTitle, styles }) => {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const startXRef = useRef(0);
  const startYRef = useRef(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    customPaging: (i) => (
      <div
        className={` ${i === settings.slickCurrentSlide ? "selected-dot" : ""}`}
        style={{
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "-25px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <ul style={{ margin: "2", padding: "0", display: "flex" }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleMouseDown = (e) => {
    startXRef.current = e.clientX;
    startYRef.current = e.clientY;
    setDragging(false);
  };

  const handleMouseMove = () => {
    setDragging(true);
  };

  const handleMouseUp = (e, blogId) => {
    const endX = e.clientX;
    const endY = e.clientY;
    const deltaX = Math.abs(endX - startXRef.current);
    const deltaY = Math.abs(endY - startYRef.current);
    if (deltaX < 5 && deltaY < 5) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        navigate(`/case-study/${blogId}`);
      }, 300); // Adjust the delay as needed for smooth scrolling
    }
    setDragging(false);
  };

  return (
    <div className="mt-16">
      <h2 className="text-white text-[20px] md:text-[40px] font-bold mx-2">
        We have a lot of success stories to share, have a look at a few
      </h2>
      <p className={`text-white ${styles}`}>{blogsTitle}</p>
      <div className="relative mt-4 mb-12 ml-8">
        <Slider {...settings}>
          {CaseStudies.map((blog) => (
            <div
              className="flex justify-center items-center cursor-pointer"
              key={blog.id}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={(e) => handleMouseUp(e, blog.id)}
            >
              <div className="h-[400px] md:h-[450px] bg-darkGray relative overflow-hidden mr-8">
                <div className="h-2/5 relative">
                  <img
                    src={blog.image}
                    alt={blog.primaryHeader}
                    className="w-full h-full object-cover"
                  />
                  {/* <div className="absolute top-0 left-0 p-2 w-full flex justify-start mt-1 ml-2">
                    <SectionTitle sectionText={blog.name} className="" />
                  </div> */}
                </div>
                <div className="h-3/5 p-4 bg-footerBackground flex flex-col justify-between">
                  <div>
                    <p className="font-semibold text-base md:text-[28px] leading-[28px] text-white mt-2 text-left">
                      {blog.primaryHeader}
                    </p>
                    <p className="text-neutral3 text-sm md:text-[18px] font-normal mt-4 text-left">
                      {blog.primaryContent}
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <img
                      onClick={() => {
                        navigate(`/case-study/${blog.id}`);
                      }}
                      src={RightArrow}
                      alt="right-arrow"
                      className="transform transition-transform duration-300 ease-in-out hover:scale-125 hover:shadow-3xl cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Blogs;

import EcommerceIcon from "../assets/industries/ecommerce-icon.svg";
import FintechIcon from "../assets/industries/fintech-icon.svg";
import FoodAndBeverageIcon from "../assets/industries/food-and-beverage-icon.svg";
import GovernmentIcon from "../assets/industries/government-icon.svg";
import HealthcareIcon from "../assets/industries/healthcare-icon.svg";
import LogisticsIcon from "../assets/industries/logistics-icon.svg";
import RealEstateIcon from "../assets/industries/real-estate-icon.svg";
import SupplyChainIcon from "../assets/industries/supply-chain-icon.svg";
import TravelIcon from "../assets/industries/travel-icon.svg";

import Ecommerce from "../assets/industries/ecommerce.png";
import Fintech from "../assets/industries/fintech.png";
import FoodAndBeverage from "../assets/industries/food-and-beverage.png";
import Government from "../assets/industries/government.png";
import Healthcare from "../assets/industries/healthcare.png";
import Logistics from "../assets/industries/logistics.png";
import RealEstate from "../assets/industries/real-estate.png";
import SupplyChain from "../assets/industries/supply-chain.png";
import Travel from "../assets/industries/travel.png";

const industriesData = [
  {
    image: FoodAndBeverage,
    icon: FoodAndBeverageIcon,
    title: "Food and Beverage",
    info: "Streamline operations, optimize inventory management, and enhance customer experiences with custom software and mobile apps",
  },
  {
    image: Travel,
    icon: TravelIcon,
    title: "Travel",
    info: "Build user-friendly booking platforms, personalize travel recommendations, and optimize logistics with custom web apps and mobile apps. Implement blockchain solutions for secure and transparent ticketing systems",
  },
  {
    image: Logistics,
    icon: LogisticsIcon,
    title: "Logistics",
    info: "Improve delivery efficiency, automate tedious tasks, and gain real-time insights with cloud-based solutions and data analytics. Utilize AI for route optimization and predictive maintenance.",
  },
  {
    image: Ecommerce,
    icon: EcommerceIcon,
    title: "E - commerce",
    info: "Create a seamless online shopping experience, personalize product recommendations, and target the right audience with data-driven marketing strategies. Implement AI-powered chatbots for enhanced customer service.",
  },
  {
    image: SupplyChain,
    icon: SupplyChainIcon,
    title: "Supply Chain",
    info: "Gain real-time visibility into your supply chain, optimize inventory management, and improve communication with partners through custom software solutions. Utilize blockchain technology for secure and transparent tracking of goods.",
  },
  {
    image: Fintech,
    icon: FintechIcon,
    title: "Fintech",
    info: "Develop secure and user-friendly financial applications, automate processes, and personalize financial products with custom software development. Leverage AI for fraud detection and risk management.",
  },
  {
    image: Healthcare,
    icon: HealthcareIcon,
    title: "Healthcare",
    info: "Enhance patient care with secure healthcare applications, streamline administrative tasks, and leverage AI for disease prediction and personalized treatment plans. Ensure data privacy and compliance with HIPAA regulations.",
  },
  {
    image: Government,
    icon: GovernmentIcon,
    title: "Government",
    info: "Improve citizen engagement, automate government services, and streamline data management with user-friendly web applications. Utilize blockchain technology for secure voting systems and transparent record-keeping.",
  },
  {
    image: RealEstate,
    icon: RealEstateIcon,
    title: "Real Estate",
    info: "Enhance patient care with secure healthcare applications, streamline administrative tasks, and leverage AI for disease prediction and personalized treatment plans. Ensure data privacy and compliance with HIPAA regulations.",
  },
];

export default industriesData;

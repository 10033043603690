import CustomSoftwareDevelopment from "../assets/services/details/custom-software-development.svg";
import WebDevelopment from "../assets/services/details/web-development.svg";
import CloudDevops from "../assets/services/details/cloud-devops.svg";
import MobileAppDevelopment from "../assets/services/details/mobile-app-development.svg";
import SoftwareTesting from "../assets/services/details/software-testing.svg";
import AIMLServices from "../assets/services/details/ai-ml-services.svg";
import BlockChainServices from "../assets/services/details/staff-augumentation.svg";
import StaffAugumentation from "../assets/services/details/blockchain-services.svg";
import DigitalBranding from "../assets/services/details/digital-branding-marketing.svg";
import DigitalBranding2 from "../assets/services/details/digital-branding2.svg";
import Consulting from "../assets/services/details/consulting.svg";
import MasterDataManagement from "../assets/services/details/master-data-management.svg";
import DataWarehouseManagement from "../assets/services/details/data-warehouse-business-intelligence.svg";
import LegacySystems from "../assets/services/details/legacy-systems-maintenance.svg";
import EnterpriseApplicationIntegration from "../assets/services/details/enterprise-application-integration.svg";
import EngineeringTechnicalStaffing from "../assets/services/details/engineering-technical-staffing.svg";
import ProfessionalStaffing from "../assets/services/details/professional-staffing.svg";

const Services = [
  {
    id: 'custom-software-development',
    title: "Custom Software Development",
    image: CustomSoftwareDevelopment,
    caption:
      "Custom Software Development involves creating tailored software solutions to meet specific business",
    techStack: ["Agile", "Innovation", "Scalable", "Tailored"],
    results: [
      {
        value: "25% reduction in average booking time",
        description:
          "Streamlined platform facilitated faster booking for shipping companies and cargo handlers",
      },
      {
        value: "18% decrease in operational costs",
        description:
          "Efficient route planning and cargo management helped Logissy cut overhead expenses.",
      },
      {
        value: "Increased client base by 15%",
        description:
          "Improved booking experience and operational efficiency attracted new clients.",
      },
    ],
  },

  {
    id: 'web-app-development',
    title: "Web App Development",
    image: WebDevelopment,
    caption:
      "Our Web App Development service caters to businesses and individuals seeking to create dynamic.",
    techStack: ["UI & UX", "Responsive", "Optimization", "Scalable"],
    results: [
      {
        value: "35% reduction in average booking time",
        description:
          "Streamlined search and booking functionalities made the process significantly faster.",
      },
      {
        value: "20% increase in user engagement",
        description:
          "uitive UI/UX design kept users engaged and exploring travel options.",
      },
      {
        value: "4.8 out of 5 star average customer satisfaction rating ",
        description:
          "Personalized recommendations and a user-friendly platform led to a surge in customer satisfaction.",
      },
    ],
  },

  {
    id: 'cloud-and-devops',
    title: "Cloud & DevOps",
    image: CloudDevops,
    caption:
      "In today's digital landscape, leveraging cloud technologies and implementing efficient DevOps",
    techStack: ["Agility", "Security", "Effective", "Scalable"],
    results: [
      {
        value: "45% faster deployment cycles",
        description:
          "Automated CI/CD pipelines enhanced efficiency, reducing time to market.",
      },
      {
        value: "30% cost savings on cloud infrastructure",
        description:
          "Optimized resource management and scaling minimized unnecessary expenses.",
      },
      {
        value: "99.95% system uptime",
        description:
          "Advanced monitoring and proactive incident response ensured reliable and continuous service.",
      },
    ],
  },

  {
    id: 'mobile-app-development',
    title: "Mobile App Development",
    image: MobileAppDevelopment,
    caption:
      "Create engaging and high-performing mobile apps for any platform.",
    techStack: ["UI & UX", "iOS", "Android", "Integration"],
    results: [],
  },

  {
    id: 'software-testing',
    title: "Software Testing",
    image: SoftwareTesting,
    caption:
      "Ensure quality and reliability with comprehensive software testing services.",
    techStack: ["Quality", "Automation", "Validation", "Perfomance"],
    results: [],
  },

  {
    id: 'ai-and-ml-services',
    title: "AI and ML services",
    image: AIMLServices,
    caption:
      "Harness the power of AI and machine learning to unlock new possibilities.",
    techStack: ["Automation", "Analytics", "Scalability", "Insights"],
    results: [],
  },

  {
    id: 'staff-augmentation',
    title: "Staff Augmentation",
    image: StaffAugumentation,
    caption:
      "Extend your team with skilled developers to accelerate your projects.",
    techStack: ["Talent", "Flexibility", "Scalability", "Expertise"],
    results: [],
  },

  {
    id: 'block-chain-solutions',
    title: "Block Chain Solutions",
    image: BlockChainServices,
    caption:
      "Explore the secure and transparent world of blockchain technology.",
    techStack: ["Security", "Transparency", "Integration", "Efficiency"],
    results: [],
  },

  {
    id: 'digital-branding-and-marketing',
    title: "Digital Branding and Marketing",
    image: DigitalBranding,
    caption:
      "Build a strong brand identity and reach your target audience effectively.",
    techStack: ["Design", "Identity", "Social Media", "Analytics"],
    results: [],
  },

  {
    id: 'talent-resourcing',
    title: "Talent Resourcing",
    image: DigitalBranding2,
    caption: "Find and place the right talent to drive your business forward.",
    techStack: ["Workforce", "Recruitment", "Hiring", "Sourcing"],
    results: [],
  },

  {
    id: 'consulting',
    title: "Consulting",
    image: Consulting,
    caption:
      "Gain expert insights and strategies to elevate your business performance.",
    techStack: ["Advisory", "Strategy", "Solutions", "Expertise"],
    results: [],
  },

  {
    id: 'master-data-migration',
    title: "Master Data Migration",
    image: MasterDataManagement,
    caption:
      "Seamlessly migrate and manage your critical data with precision and security.",
    techStack: ["Integration", "Migration", "Transformation", "Consolidation"],
    results: [],
  },

  {
    id: 'data-warehousing-and-business-intelligence',
    title: "Data Warehousing & Business Intelligence",
    image: DataWarehouseManagement,
    caption:
      "Transform data into actionable insights for smarter decision-making.",
    techStack: ["Storage", "Analytics", "Reporting", "Insights"],
    results: [],
  },

  {
    id: 'legacy-systems-maintenance-and-migration',
    title: "Legacy Systems Maintenance & Migration",
    image: LegacySystems,
    caption:
      "Upgrade and maintain legacy systems to ensure smooth, future-proof operations.",
    techStack: ["Legacy", "Integration", "Maintenance", "Migration"],
    results: [],
  },

  {
    id: 'enterprise-application-integration',
    title: "Enterprise Application Integration",
    image: EnterpriseApplicationIntegration,
    caption:
      "Integrate your enterprise applications for enhanced efficiency and collaboration.",
    techStack: ["Integration", "Automation", "API's", "Dataflow"],
    results: [],
  },

  {
    id: 'engineering-and-technical-staffing',
    title: "Engineering and Technical Staffing",
    image: EngineeringTechnicalStaffing,
    caption:
      "Source top engineering and technical talent to meet your project needs.",
    techStack: ["Technical", "Engineers", "Specialists", "Skills"],
    results: [],
  },
  {
    id: 'professional-staffing',
    title: "Professional Staffing",
    image: ProfessionalStaffing,
    caption:
      "Hire professional staff to enhance your organization's capabilities.",
    techStack: ["Talent", "Skillset", "Consultancy", "Experts"],
    results: [],
  },
];

export default Services;

import React from "react";
import { FaArrowUpLong } from "react-icons/fa6";

const ScrollToTop = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <button
        onClick={handleScrollToTop}
        className="fixed bottom-4 right-4 p-3 bg-gradient-to-r from-purple-500 to-purple-400 text-white rounded-full shadow-lg"
      >
        <FaArrowUpLong className="pl-[2px]" />
      </button>
    </div>
  );
};

export default ScrollToTop;

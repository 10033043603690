import ContactUs from "../components/contact-us/ContactUs";
import { Helmet } from "react-helmet";

export default function ContactUsPage() {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Contact Us</title>
        <meta
          name="description"
          content="Reach out to us and begin your path to a more promising future. Once you submit the form, one of our representative will contact you."
        />
      </Helmet>
      <ContactUs />
    </>
  );
}

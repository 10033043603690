import SectionTitle from "../shared/SectionTitle";

export default function TermsAndConditions() {
  return (
    <div className="text-white py-4 md:py-8 px-2 text-left">
      <SectionTitle
        sectionText="Visibla Solution's"
        className="justify-center"
      />
      <h1 className="my-4 md:my-8 text-center text-xl md:text-[40px] font-semibold">
        Terms & Conditions
      </h1>

      <main className="space-y-8">
        <section>
          <p className="mt-2 text-xs md:text-lg font-normal text-center">
            Welcome to Visibla Solutions!
            <span className="block">
              These terms and conditions govern your use of the Visibla
              Solutions website and services. By accessing or using the Visibla
              Solutions website at www.visiblasolutions.com or any Visibla
              Solutions services, you agree to be bound by these terms and
              conditions and our Privacy Policy. If you do not agree with any
              part of these terms, you must not use the Visibla Solutions
              website or services.
            </span>
          </p>
        </section>

        {/* Information we collect section */}
        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Definitions
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            "Website" refers to Visibla Solutions's website located at
            www.visibla.com and all sub-domains. "Services" refers to all
            services provided by Visibla Solutions. "Content" refers to any
            text, images, video, audio or other information available on the
            Website or provided through the Services. "You" and "your" refer to
            you, the user of the Website and/or Services.
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Use of Website and Services
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            Your use of the Website and Services is subject to all applicable
            local, state, national and international laws and regulations. You
            agree not to reproduce, duplicate, copy, sell, resell or exploit any
            portion of the Website or Services without Visibla Solutions's
            express written permission.
            <span className="block">
              {" "}
              You must not access the Website or Services through any automated
              means such as scripts or web crawlers. You are solely responsible
              for any information provided when using the Website or Services.
            </span>
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Intellectual Property
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            The Website, Services, and all Content are owned by Visibla
            Solutions and are protected by intellectual property laws. You may
            access and view the Content, but must not modify, distribute,
            transmit or republish it in any form without prior written consent
            from Visibla Solutions.
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            User Content
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            The Website may allow you to post comments, reviews or other
            content. You are solely responsible for any content you submit and
            warrant that it does not violate any laws or third-party rights.
            Visibla Solutions reserves the right to remove or modify any user
            content at its sole discretion.
            <span className="block">
              {" "}
              By submitting content, you grant Visibla Solutions a perpetual,
              worldwide, non-exclusive license to use, reproduce, modify and
              distribute your content in any way
            </span>
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Links to Third-Party Sites
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            The Website may contain links to third-party sites for your
            convenience. Visibla Solutions does not control and is not
            responsible for the content or policies of linked sites.
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Limitation of Liability
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            Visibla Solutions will not be liable for any damages, direct or
            indirect, arising from your use of the Website, Services or any
            linked third-party sites. Your use is solely at your own risk.
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Termination
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            Visibla Solutions may terminate or suspend your access to the
            Website and Services at any time, with or without notice, for any
            reason.
          </p>
        </section>

        <section>
          <h2 className="text-base md:text-[28px] font-semibold mb-2">
            Changes to Terms
          </h2>
          <hr />
          <p className="mt-2 text-xs md:text-lg font-normal">
            Visibla Solutions may change these Terms of Use at any time by
            posting the updated version on the Website. Your continued use
            constitutes acceptance of any changes.
            <span className="block">
              {" "}
              Contact Us If you have any questions, please contact us at{" "}
              <a href="mailto:support@visibla.com">support@visibla.com</a>.
            </span>
            <span className="block">
              {" "}
              These terms were last updated on [current date].
            </span>
          </p>
        </section>
      </main>
    </div>
  );
}

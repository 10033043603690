import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReadMore from "../shared/ReadMore";
import BackButton from "../shared/BackButton";

export default function BlogDetails() {
  const { blogId } = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getBlogDetails`,
          { blogId }
        );
        setBlogDetails(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [blogId]);

  if (loading) {
    return <div className="py-8 text-left">Loading...</div>;
  }

  const handleSmoothScroll = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const blogImageUrl = blogDetails.blogImage;

  return (
    <div>
      <BackButton navigateTo="/blogs" className="mb-4 mt-4 md:mt-6 md:mb-2" />
      <div className="flex flex-col text-white text-left">
        <div className="relative flex flex-col justify-center items-center text-center text-white h-[20vh] md:h-[50vh] lg:h-[82vh] w-full mt-4">
          <div className="absolute inset-0 bg-cover bg-center opacity-50">
            <img src={blogImageUrl} alt="" />
          </div>
          <div className="relative z-10">
            <h1 className="text-2xl md:text-[40px] font-semibold px-4 lg:px-16">
              {blogDetails.blogTitle}
            </h1>
          </div>
        </div>

        <main className="px-4 lg:px-16">
          <p className="text-lg font-normal pt-6">
            {blogDetails.blogDescription}
          </p>

          {/* Table of Contents */}
          <section className="my-8 bg-sectionTitle p-6 rounded-md">
            <h2 className="text-xl md:text-[28px] font-semibold">
              Table Of Contents
            </h2>
            <hr />
            <ul className="list-disc list-inside px-2 md:px-8 pt-2">
              {blogDetails.blogContent.map((content, index) => (
                <li key={index} className="my-2">
                  <button
                    onClick={() => handleSmoothScroll(`section-${index}`)}
                    className="text-base md:text-[22px] text-white underline focus:outline-none"
                  >
                    {content.contentTitle}
                  </button>
                </li>
              ))}
            </ul>
          </section>

          {blogDetails.blogContent.map((content, index) => (
            <section
              id={`section-${index}`}
              className="my-10"
              key={content.contentId}
            >
              {!content.contentImage && (
                <>
                  <h2 className="text-[28px] font-semibold">
                    {content.contentTitle}
                  </h2>
                  <hr />
                  <p className="text-lg font-normal pt-4">
                    {content.contentDescription}
                  </p>
                </>
              )}
              {content.contentImage && (
                <div
                  className={`flex flex-col md:flex-row items-start mt-4 ${
                    index % 2 === 0 ? "" : "md:flex-row-reverse"
                  }`}
                >
                  <img
                    src={content.contentImage}
                    alt={content.contentTitle}
                    className={`md:w-1/2 ${
                      index % 2 === 0 ? "md:mr-8" : "md:ml-8"
                    } mb-4 md:mb-0`}
                  />
                  <div className="my-auto">
                    <h2 className="text-[28px] font-semibold">
                      {content.contentTitle}
                    </h2>
                    <hr />
                    <p className="text-lg font-normal pt-4">
                      {content.contentDescription}
                    </p>
                  </div>
                </div>
              )}
            </section>
          ))}

          <ReadMore
            items={blogDetails.relatedBlog.map((blog) => ({
              id: blog.blogId,
              image: blog.blogImage,
              categoryName: blog.blogCategoryName,
              title: blog.blogTitle,
              description: blog.blogDescription,
            }))}
            title="Read more blogs like this"
            linkPrefix="blog-details"
          />
        </main>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import VerticalLine from "../../assets/background/vertical-line.svg";
import AboutUsHeroBg from "../../assets/hero/about-us-hero.png";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const services = [
    "Custom Software Development",
    "Web App Development",
    "Mobile App Development",
    "Digital Branding & Marketing",
    "Software Testing",
  ];

  const duplicatedServices = [...services, ...services];

  return (
    <>
      <div
        className="text-white"
        style={{
          backgroundImage: `url(${AboutUsHeroBg})`,
          backgroundPosition: "center top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1
          style={{ textShadow: "4px 4px 8px rgba(0, 0, 0, 0.7)" }}
          className="shadow-xl text-shadow  text-[36px] md:text-[80px] font-bold h-[70vh] pt-36"
        >
          We Engineer Transformation
        </h1>
        <div className="about-us-gradient-left">
          <div className="about-us-gradient-right">
            <div className="">
              <h2 className="font-bold text-lg md:text-4xl mt-[80px] mb-8">
                Your Trusted Partner for Digital Product Development
              </h2>
              <p className="text-[14px] md:text-[28px] mb-8 md:mb-16">
                In today's dynamic digital landscape, businesses need agile
                partners who can translate vision into reality. At Visibla
                Solutions, we combine our passion for innovation with a deep
                understanding of digital product development to help businesses
                achieve their goals.
              </p>
            </div>

            <h2 className="font-bold text-lg md:text-4xl mb-8 text-gray-400">
              Our Expertise: Building the Future, Together
            </h2>
            <p className="text-[14px] md:text-[28px] mb-8 md:mb-16 text-gray-400">
              Our team of experienced developers, designers, and strategists
              works collaboratively with you to craft custom software solutions,
              mobile apps, and web applications that deliver exceptional user
              experiences. We leverage cutting-edge technologies like AI,
              machine learning, and cloud computing to create future-proof
              solutions that scale with your business.
            </p>

            {/* <p className="relative text-[28px] before-absolute before:inset-0 before:bg-white before:animate-typewriter after:absolute after:inset-0 after:bg-black after:animate-caret">
          In today's dynamic digital landscape, businesses need agile partners
          who can translate vision into reality. At Visibla Solutions, we
          combine our passion for innovation with a deep understanding of
          digital product development to help businesses achieve their goals.
        </p> */}

            <h3 className="font-semibold mt-8 text-sm md:text-[28px] mb-8">
              Breakthrough Innovation, Delivered Faster
            </h3>
            <div className="flex flex-col md:flex-row text-left gap-2 bg-transparent">
              <div className="md:basis-1/4">
                <p className="text-sm md:text-xl text-secondary border-l border-l-secondary pl-4 pb-4">
                  Website, Application & Custom Software Development
                </p>
                <p className="text-xs md:text-base border-l border-l-darkGray pl-4">
                  Drive success through custom digital solutions.
                </p>
              </div>
              <div className="basis-1/4">
                <p className="text-sm md:text-xl text-secondary border-l border-l-secondary pl-4 pb-4">
                  SEO, SEM & Social Media Marketing
                </p>
                <p className="text-xs md:text-base border-l border-l-darkGray pl-4">
                  Drive qualified traffic and brand awareness.
                </p>
              </div>
              <div className="basis-1/4">
                <p className="text-sm md:text-xl text-secondary border-l border-l-secondary pl-4 pb-4">
                  Content Marketing & Data Analytics
                </p>
                <p className="text-xs md:text-base border-l border-l-darkGray pl-4">
                  Attract, convert, and optimize your marketing efforts.
                </p>
              </div>
              <div className="md:basis-1/4">
                <p className="text-sm md:text-xl text-secondary border-l border-l-secondary pl-4 pb-4">
                  Talent sourcing
                </p>
                <p className="text-xs md:text-base border-l border-l-darkGray pl-4">
                  Hire the best in the industry, without breaking the bank.
                </p>
              </div>
            </div>
            <p className="text-xs md:text-lg my-8 md:my-16">
              We don't just build, we collaborate. Our team tackles your
              challenges, translating your vision into solutions that propel
              your business forward.
            </p>
          </div>
        </div>
      </div>

      {/* Our story section */}
      <div className="bg-tertiary text-white p-8 md:p-12">
        <p className="font-semibold text-sm md:text-[28px]"> Our story</p>
        <p className="text-xs md:text-lg">
          Founded in 2014, located in India's tech hub- Bangalore. We strive to
          be a globally recognised top Digital product development and talent
          sourcing firm, thriving in the fast-paced digital world. With a track
          record of successfully handling numerous projects so far, we're ready
          to take on even more and deliver outstanding results.
        </p>
      </div>

      {/* Marquee section */}
      <h2 className="text-sm md:text-[28px] text-white font-semibold mt-16 mb-8">
        Your one stop for all your business needs
      </h2>
      <div className="flex w-full overflow-hidden">
        <div className="flex  gap-2 md:gap-4 items-center whitespace-nowrap animate-scrollX">
          {duplicatedServices.map((service, index) => (
            <div
              key={index}
              className="grid place-items-center px-4 bg-secondary ml-2 mr-2 py-3 shadow-innerRight rounded-3xl"
            >
              <span className="font-medium text-xs md:text-lg"> {service}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Life at Visibla section */}
      <div className="relative min-h-screen flex flex-col items-center justify-center p-4 my-12">
        <div className="absolute inset-0 flex justify-between w-full px-4 z-0">
          <img src={VerticalLine} alt="Vertical Line" />
          <img src={VerticalLine} alt="Vertical Line" />
          <img src={VerticalLine} alt="Vertical Line" />
          <img src={VerticalLine} alt="Vertical Line" />
        </div>
        <div className="relative z-10 text-left mr-auto">
          <h1 className="text-[28px] md:text-[54px] font-bold mb-4 absolute md:ml-[36%] pt-2 text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            Life at Visibla
          </h1>
          <div className="bg-gray18 text-white pt-16 md:pt-24 pb-8 md:pb-12 px-3 md:px-16 md:w-1/2 mr-auto mb-4">
            <p className="text-sm md:text-base">
              We’re more than just coders – we’re innovators. In a constantly
              evolving digital world, we’re always ready to explore new ideas
              and technologies. At Visibla Solutions, we’re all about making
              great software that helps businesses succeed. Our team of experts
              covers everything from coding to project management, so we can
              tackle any challenge head-on.
            </p>
          </div>
        </div>
        <div className="relative z-10 text-left ml-auto">
          <h2 className="text-[28px] md:text-[54px] font-bold text-white mb-4 pt-6 md:pt-16">
            What makes us different?
          </h2>
          <div className="bg-gray18 text-white pt-16 md:pt-24 pb-8 md:pb-12 px-3 md:px-16 md:w-1/2 ml-auto -mt-16">
            <p className="text-sm md:text-base">
              We’re more than just coders – we’re innovators. In a constantly
              evolving digital world, we’re always ready to explore new ideas
              and technologies. At Visibla Solutions, we’re all about making
              great software that helps businesses succeed. Our team of experts
              covers everything from coding to project management, so we can
              tackle any challenge head-on.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

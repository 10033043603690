const Button = ({
  buttonText = "Reusable Button Components",
  onClick,
  className = "",
  loading,
}) => {
  return (
    <div className={`${className}`}>
      <button
        className={`rounded-full h-10 md:h-14 lg:h-16 py-2 px-4 md:px-5 lg:px-6 cursor-pointer bg-gradient-to-r from-purple-500 to-purple-400 text-white text-center transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg`}
        onClick={onClick}
      >
        {loading ? "Loading..." : buttonText}
      </button>
    </div>
  );
};

export default Button;

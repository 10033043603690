import React, { useEffect } from "react";
import Button from "../shared/Button";
import Separator from "../../assets/case-study/separator.svg";
import CaseStudies from "../../data/CaseStudies";
import { Link } from "react-router-dom";

const CaseStudyMain = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="px-4 md:px-8 mt-8">
      <div className="text-center md:text-left">
        <p className="text-white text-center font-semibold text-2xl md:text-4xl">
          Success stories, elevate business
        </p>
        <p className="text-neutral3 mt-2 text-center">
          In Business, speed is everything. We transform existing solutions and
          build new ones, all within your timeline
        </p>
      </div>
      {CaseStudies.map((casestudy) => (
        <div key={casestudy.id}>
          <div>
            <div className="mt-12 md:mt-24 flex flex-col md:flex-row justify-evenly items-center">
              <div className="w-full md:w-2/5 h-auto md:h-[365px] flex justify-center">
                <img
                  src={casestudy.image}
                  alt={casestudy.name}
                  className="w-full md:w-[564px]"
                />
              </div>
              <div className="w-full md:w-3/5 h-auto md:h-[365px] mt-8 md:mt-0 md:ml-16 text-left">
                <img
                  src={casestudy.logo}
                  alt={casestudy.name}
                  className="h-14 w-full md:w-[300px] md:-ml-[1.2rem]"
                />
                <div className="mt-4">
                  <p className="text-lg md:text-xl text-white">
                    {casestudy.primaryHeader}
                  </p>
                  <p className="text-neutral3 text-base mt-1">
                    {casestudy.primaryContent}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-neutral3 text-base">
                    {casestudy.secondaryHeader}
                  </p>
                  <p className="text-base md:text-lg text-white mt-2">
                    {casestudy.secondaryContent}
                  </p>
                </div>
                <div className="mt-8">
                  <p className="text-neutral3 text-base">
                    {casestudy.tertiaryHeader}
                  </p>
                  <p className="text-base md:text-lg text-white mt-2">
                    {casestudy.tertiaryContent}
                  </p>
                </div>
              </div>
            </div>

            {/* Results */}
            <div className="mt-8">
              <p className="text-base text-neutral3 ml-4 text-left">Results</p>
              <div className="flex flex-col md:flex-row justify-between">
                {casestudy.results.map((result, index) => (
                  <div
                    key={index}
                    className="text-left gap-2 mt-4 basis-full md:basis-1/3"
                  >
                    <div className="border-l border-l-neutral3 pl-4">
                      <p className="text-lg md:text-xl text-secondary">
                        {result.value}
                      </p>
                      <p className="text-white mt-6">{result.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="text-left mt-10">
              <Link to={`/case-study/${casestudy.id}`}>
                <Button buttonText="View Case Study" className="" />
              </Link>
            </div>

            <div className="mt-10 mb-4">
              <img src={Separator} alt="separator" className="w-full" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CaseStudyMain;

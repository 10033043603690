import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function NewsMain() {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [filterOptions, setFilterOptions] = useState(["All"]);
  const [latestNews, setLatestNews] = useState(null);
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const pageSize = 7;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getAllNewsCategory`
        );
        const categories = response.data.data.categoryList.map(
          (category) => category.newsCategoryName
        );
        setFilterOptions(["All", ...categories]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getAllNews`
        );
        setLatestNews(response.data.data.latestNews[0]);
        setNewsData(response.data.data.newsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news:", error);
        setLoading(false);
      }
    };

    fetchCategories();
    fetchNews();
  }, []);

  const filteredData =
    selectedFilter === "All"
      ? newsData
      : newsData.filter((news) =>
          news.newsCategoryName.includes(selectedFilter)
        );

  useEffect(() => {
    // setTotalPages(Math.ceil(filteredData.length / pageSize));
    setCurrentPage(1);
  }, [filteredData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  if (loading) {
    return <div className="py-8 text-left">Loading...</div>;
  }

  const truncateDescription = (description) => {
    return description.length > 200
      ? description.slice(0, 200) + "..."
      : description;
  };

  const handleNewsClick = (newsId) => {
    navigate(`/news-details/${newsId}`);
  };

  return (
    <div className="py-4 md:py-8 text-left">
      {/* Filter options */}
      <div className="flex flex-wrap gap-4 my-4">
        {filterOptions.map((filter, index) => (
          <button
            key={index}
            className={`px-6 md:px-8 py-2 md:py-3 rounded-full text-sm lg:text-base ${
              selectedFilter === filter
                ? "border-primary border text-white"
                : "bg-footerBackground text-neutral3 hover:text-white hover:bg-transparent hover:bg-primary"
            }`}
            onClick={() => setSelectedFilter(filter)}
          >
            {filter}
          </button>
        ))}
      </div>

      {/* Latest news item */}
      {latestNews && selectedFilter === "All" && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:mb-8 text-white cursor-pointer">
          <div
            className="col-span-1 md:col-span-2 bg-black py-4 rounded-lg shadow-lg grid grid-cols-1 md:grid-cols-2 gap-4 cursor-pointer"
            onClick={() => handleNewsClick(latestNews.newsId)}
          >
            <div className="relative">
              <img
                src={latestNews.newsImage}
                alt={`${latestNews.newsTitle}`}
                className="w-full h-full object-cover rounded-l-lg"
              />
            </div>
            <div className="flex flex-col justify-center text-left pb-4 md:pb-24 pr-24 md:pr-0 lg:pr-24">
              <p className="mb-auto text-sm font-medium text-neutral3">
                {latestNews.newsCategoryName}
              </p>
              <h2 className="text-[24px] font-semibold">
                {latestNews.newsTitle}
              </h2>
              <p className="text-[16px] mt-2">
                {truncateDescription(latestNews.newsDescription)}
              </p>
              <p className="text-sm font-medium text-neutral3 mt-4">
                By {latestNews.newsWrittenBy}{" "}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Other news items */}
      {paginatedData.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 mt-2 md:mt-8">
            {paginatedData.map((news, index) => (
              <div
                key={index}
                className="bg-black text-white p-4 rounded-lg shadow-lg cursor-pointer"
                onClick={() => handleNewsClick(news.newsId)}
              >
                <div className="relative">
                  <p className="mb-auto text-sm font-medium text-neutral3 pb-4">
                    {news.newsCategoryName}
                  </p>
                  <img
                    src={news.newsImage}
                    alt={`${news.newsTitle}`}
                    className="w-full h-auto rounded-t-lg"
                  />
                </div>
                <div className="md:py-4">
                  <h2 className="text-[20px] font-semibold">
                    {news.newsTitle}
                  </h2>
                  <p className="text-[14px] mt-2">
                    {truncateDescription(news.newsDescription)}
                  </p>
                  <p className="text-sm font-medium text-neutral3 mt-4">
                    By {news.newsWrittenBy}{" "}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination hidden for now */}
          {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          /> */}
        </>
      ) : (
        <div className="py-8 text-center text-neutral3">
          No news items available for the selected category.
        </div>
      )}
    </div>
  );
}

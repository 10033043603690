import CustomBuilt from "../../assets/tools/custom-built.svg";
import FlawlessFunctionality from "../../assets/tools/flawless-functionality.svg";
import HumanTouch from "../../assets/tools/human-touch.svg";
import OneStopDestination from "../../assets/tools/one-stop-destination.svg";

const Tools = () => {
  return (
    <div className=" mt-6 ">
      <div className="px-2 md:px-6 mt-6">
        <h1 className="text-white sm:text-[20px] md:text-[40px] font-semibold ">
          We strive to be the most customer centric digital product <br />{" "}
          developers and sourcing partners around the globe.
        </h1>
      </div>
      <div className="px-4 md:px-12 mt-8 flex flex-col md:flex md:flex-row justify-center align-center gap-4">
        <div className="text-left w-full md:w-72">
          <img src={CustomBuilt} alt="CustomBuilt" loading="lazy" />
          <div className=" mt-4 md:mt-10">
            <h1 className="text-white font-semibold text-base md:text-2xl h-10 md:h-20">
              Custom Built
            </h1>
            <p className="text-neutral3 font-normal text-xs md:text-lg mt-0">
              Solutions crafted specifically to meet your unique business needs
            </p>
          </div>
        </div>

        <div className="text-left w-full md:w-72">
          <img src={FlawlessFunctionality} alt="CustomBuilt" loading="lazy" />
          <div className="mt-4 md:mt-10">
            <h1 className="text-white text-nowrap font-semibold text-base md:text-2xl h-10 md:h-20">
              Flawless Functionality
            </h1>
            <p className="text-neutral3 font-normal text-xs md:text-lg mt-0">
              Software that works seamlessly, every single time
            </p>
          </div>
        </div>

        <div className="text-left w-72">
          <img src={OneStopDestination} alt="CustomBuilt" loading="lazy" />
          <div className="mt-4 md:mt-10">
            <h1 className="text-white font-semibold text-base md:text-2xl h-10 md:h-20">
              One-Stop Destination
            </h1>
            <p className="text-neutral3 font-normal text-xs md:text-lg mt-0">
              We handle everything, from concept to development and beyond
            </p>
          </div>
        </div>

        <div className="text-left w-72">
          <img src={HumanTouch} alt="CustomBuilt" loading="lazy" />
          <div className="mt-4 md:mt-10">
            <h1 className="text-white font-semibold text-base md:text-2xl h-10 md:h-20">
              Human Touch
            </h1>
            <p className="text-neutral3 font-normal text-xs md:text-lg mt-0">
              Dedicated experts who understand your vision and goals
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;

import Industries from "../components/industries/Industries";
import { Helmet } from "react-helmet";

export default function IndustriesPage() {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Industries</title>
        <meta
          name="description"
          content="IT solutions tailored to diverse industries. We serve a wide range of industries by providing custom software development solutions which meet the specific structure."
        />
      </Helmet>
      <Industries />
    </>
  );
}

import React, { useEffect, useState } from "react";
import Button from "../shared/Button";
import axios from "axios";

const Careers = () => {
  const [careerList, setCareerList] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [submitData, setSubmitData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    careersCategoryId: "2",
    linkedInLink: "",
    personalWebsiteLink: "",
  });

  const getAllCareerCategories = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getAllCareerCategory`,
        {}
      );

      setCareerList(response.data.data.categoryList);
    } catch (error) {
      console.error(error);
    }
  };

  const submitCareerDetails = async () => {
    try {
      let fileUrl;

      const isEmpty = Object.values(submitData).some((value) => value === "");
      if (isEmpty) {
        alert("Please fill out all the entries to proceed!");
        return;
      }
      if (!file) {
        alert("Please select your resume to proceed!");
        return;
      }
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const fileResp = await axios.post(
        `${process.env.REACT_APP_API_URL}/uploadDocument`,
        formData
      );
      if (fileResp.data.meta.status) {
        fileUrl = fileResp.data.data.fileUrl;
      } else {
        alert("Cannot upload file!");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submitCareer`,
        { ...submitData, resumePdfLink: fileUrl }
      );
      setLoading(false);
      if (response.data.meta.status) {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      } else {
        alert(response.data.meta.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getAllCareerCategories();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (e) => {
    setSubmitData((prev) => ({
      ...prev,
      careersCategoryId: e.target.value,
    }));
  };

  return (
    <div className="about-us-gradient-right">
      <div className="text-white about-us-gradient-left">
        <h1 className="text-[40px] font-semibold text-center text-white">
          Careers
        </h1>
        <p className="mt-2 text-lg font-normal">
          Explore Career Opportunities-Interested in joining our dynamic team?{" "}
          <br />
          Share your resume with us and choose your area of expertise. We are
          always on lookout for talented individuals.
        </p>
        <form className="flex flex-col md:flex-row flex-wrap justify-between py-8">
          <div className="flex flex-col w-full lg:w-1/2 p-2 pb-0 lg:pb-2 text-left gap-y-2">
            <div>
              <label
                htmlFor="name"
                className="block font-normal text-[18px] my-2"
              >
                First Name
              </label>
              <input
                type="text"
                id="name"
                onChange={(e) => {
                  setSubmitData((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }));
                }}
                placeholder="Enter your first name"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>
            <div className="lg:hidden">
              <label
                htmlFor="name"
                className="block font-normal text-[18px] my-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="name"
                onChange={(e) => {
                  setSubmitData((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }));
                }}
                placeholder="Enter your last name"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block font-normal text-[18px] my-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={submitData.email}
                onChange={(e) => {
                  setSubmitData((prev) => ({ ...prev, email: e.target.value }));
                }}
                placeholder="Enter your Email Id"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>

            <div>
              <label
                htmlFor="project"
                className="block font-normal text-[18px] my-2"
              >
                Resume (PDF)
              </label>
              <input
                type="file"
                id="project"
                accept="application/pdf"
                onChange={(e) => setFile(e.target.files[0])}
                placeholder="Upload resume in pdf format (max.20 Mb)"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>
            <div>
              <label
                htmlFor="website"
                className="block font-normal text-[18px] my-2"
              >
                Personal Website Link
              </label>
              <input
                type="text"
                id="website"
                value={submitData.personalWebsiteLink}
                onChange={(e) => {
                  setSubmitData((prev) => ({
                    ...prev,
                    personalWebsiteLink: e.target.value,
                  }));
                }}
                placeholder="Paste your personal website link"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>
          </div>

          <div className="flex flex-col w-full lg:w-1/2 p-2 pb-0 justify-between text-left gap-y-2">
            <div className="hidden lg:block">
              <label
                htmlFor="name"
                className="block font-normal text-[18px] my-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="name"
                onChange={(e) => {
                  setSubmitData((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }));
                }}
                placeholder="Enter your last name"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>

            <div>
              <label
                htmlFor="interest"
                className="block font-normal text-[18px] my-2"
              >
                Interested in
              </label>
              <select
                id="interest"
                className="block p-4 h-[60px] w-full lg:w-[510px] rounded-md bg-brandBackground border-[#474747]"
                value={submitData.careersCategoryId}
                onChange={handleChange}
              >
                {careerList.map((careerType) => (
                  <option
                    key={careerType.careersCategoryId}
                    value={careerType.careersCategoryId}
                  >
                    {careerType.careersCategoryName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="linkedin"
                className="block font-normal text-[18px] my-2"
              >
                LinkedIn Link
              </label>
              <input
                type="text"
                id="linkedin"
                value={submitData.linkedInLink}
                onChange={(e) => {
                  setSubmitData((prev) => ({
                    ...prev,
                    linkedInLink: e.target.value,
                  }));
                }}
                placeholder="Paste your LinkedIn link"
                className="p-4 h-[60px] w-full lg:w-[510px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>
            {isSubmitted ? (
              <span className="text-white">Thank you for submitting!</span>
            ) : (
              <Button
                type="button"
                loading={loading}
                buttonText="Apply now"
                onClick={(e) => {
                  e.preventDefault();
                  submitCareerDetails();
                }}
                className="h-14 lg:w-[515px] mt-2 lg:mt-0"
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Careers;

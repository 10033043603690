import React from "react";
import BackArrow from "../../assets/back-left-arrow.svg";
import { useNavigate } from "react-router-dom";

const BackButton = ({ className = "", navigateTo = "" }) => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate(navigateTo);
  };
  return (
    <div
      className={`flex gap-2 cursor-pointer ${className}`}
      onClick={handleBackButton}
    >
      <img src={BackArrow} alt="back-arrow" />
      <p className="sm:text-xs md:text-lg text-white">Back</p>
    </div>
  );
};

export default BackButton;

import { useEffect } from "react";
import Accordion from "./Accordion";
import Button from "./Button";

import { Link } from "react-router-dom";

export default function Faq({ isHome }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="mx-auto py-4 my-8">
      <h1 className="text-[20px] md:text-[40px]  font-semibold tracking-tighter text-center text-white">
        FAQs
      </h1>
      <div className="text-center bg-black py-4 md:px-4 md:p-6 rounded-2xl ml-auto mr-auto">
        <Accordion className="text-left" isHome={isHome} />
      </div>
      <div>
        {isHome && (
          <Link to="/faqs">
            <Button buttonText="View more" />
          </Link>
        )}
      </div>
    </div>
  );
}

import React from "react";
import ServicesDetails from "../components/services/ServicesDetails";
import { Helmet } from "react-helmet";

const ServicesDetailsPage = () => {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Services</title>
        <meta
          name="description"
          content="Expert IT Solutions: Web development, mobile app development, and custom software development. Innovate with our comprehensive development solutions."
        />
      </Helmet>
      <ServicesDetails />
    </>
  );
};

export default ServicesDetailsPage;

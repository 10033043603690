import { useNavigate } from "react-router-dom";
import RightArrow from "../../assets/icons/blog-right-arrow.svg";

const ReadMore = ({ items, title, linkPrefix }) => {
  const navigate = useNavigate();

  const handleItemClick = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/${linkPrefix}/${id}`);
  };

  return (
    <section className="mt-16 mb-8">
      <h2 className="text-[28px] font-semibold">{items.length > 0 && title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-4">
        {items.map((item) => (
          <div
            key={item.id}
            className="bg-black text-white py-4 rounded-lg shadow-lg cursor-pointer min-h-[450px] transform transition-transform hover:scale-105"
            onClick={() => handleItemClick(item.id)}
          >
            <div className="relative">
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-auto rounded-t-lg"
              />
              <div className="absolute top-4 right-4">
                <p className="bg-black px-2 py-1 rounded text-xs">
                  {item.categoryName}
                </p>
              </div>
            </div>
            <div className="py-4 px-6 bg-gray82 min-h-[280px] flex flex-col">
              <h3 className="text-[20px] font-semibold">{item.title}</h3>
              <p className="text-[14px] mt-2">
                {item.description.length > 100
                  ? `${item.description.slice(0, 100)}...`
                  : item.description}
              </p>
              <img src={RightArrow} alt="arrow" className="ml-auto mt-auto" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ReadMore;

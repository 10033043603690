import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Pagination from "../shared/Pagination";

export default function BlogsMain() {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [filterOptions, setFilterOptions] = useState(["All"]);
  const [latestBlog, setLatestBlog] = useState(null);
  const [blogsData, setBlogsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const pageSize = 6;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getAllBlogCategory`
        );
        const categories = response.data.data.categoryList.map(
          (category) => category.blogCategoryName
        );
        setFilterOptions(["All", ...categories]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchBlogs = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getAllBlogs`
        );
        setLatestBlog(response.data.data.latestBlog[0]);
        setBlogsData(response.data.data.blogList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      }
    };

    fetchCategories();
    fetchBlogs();
  }, []);

  const handleBlogClick = (blogId) => {
    navigate(`/blog-details/${blogId}`);
  };

  const filteredData =
    selectedFilter === "All"
      ? blogsData
      : blogsData.filter((blog) =>
          blog.blogCategoryName.includes(selectedFilter)
        );

  useEffect(() => {
    // setTotalPages(Math.ceil(filteredData.length / pageSize));
    setCurrentPage(1);
  }, [filteredData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  if (loading) {
    return <div className="py-8 text-left">Loading...</div>;
  }

  const truncateDescription = (description) => {
    return description.length > 200
      ? description.slice(0, 200) + "..."
      : description;
  };

  return (
    <div className="py-4 md:py-8 text-left">
      {/* Filter options */}
      <div className="flex flex-wrap gap-4 my-4">
        {filterOptions.map((filter, index) => (
          <button
            key={index}
            className={`px-6 md:px-8 py-2 md:py-3 rounded-full text-sm lg:text-base ${
              selectedFilter === filter
                ? "border-primary border text-white"
                : "bg-footerBackground text-neutral3 hover:text-white hover:bg-transparent hover:bg-primary"
            }`}
            onClick={() => setSelectedFilter(filter)}
          >
            {filter}
          </button>
        ))}
      </div>

      {/* Latest blog */}
      {latestBlog && selectedFilter === "All" && (
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-8 md:mb-8 text-white cursor-pointer"
          onClick={() => handleBlogClick(latestBlog.blogId)}
        >
          <div className="col-span-1 md:col-span-2 bg-black py-4 rounded-lg shadow-lg grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative">
              <img
                src={latestBlog.blogImage}
                alt={`${latestBlog.blogTitle}`}
                className="w-full h-full object-cover rounded-l-lg"
              />
            </div>
            <div className="flex flex-col justify-center text-left pb-4 md:pb-24 pr-24 md:pr-0 lg:pr-24">
              <p className="mb-auto text-sm font-medium text-neutral3">
                {latestBlog.blogCategoryName}
              </p>
              <h2 className="text-[24px] font-semibold">
                {latestBlog.blogTitle}
              </h2>
              <p className="text-[16px] mt-2">
                {truncateDescription(latestBlog.blogDescription)}
              </p>
              <p className="text-sm font-medium text-neutral3 mt-4">
                By {latestBlog.blogWrittenBy}{" "}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Other blogs */}
      {paginatedData.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 mt-2 md:mt-8">
            {paginatedData.map((blog, index) => (
              <div
                key={index}
                className="bg-black text-white p-4 rounded-lg shadow-lg cursor-pointer"
                onClick={() => handleBlogClick(blog.blogId)}
              >
                <div className="relative">
                  <p className="mb-auto text-sm font-medium text-neutral3 pb-4">
                    {blog.blogCategoryName}
                  </p>
                  <img
                    src={blog.blogImage}
                    alt={`${blog.blogTitle}`}
                    className="w-full h-auto rounded-t-lg"
                  />
                </div>
                <div className="md:py-4">
                  <h2 className="text-[20px] font-semibold">
                    {blog.blogTitle}
                  </h2>
                  <p className="text-[14px] mt-2">
                    {truncateDescription(blog.blogDescription)}
                  </p>
                  <p className="text-sm font-medium text-neutral3 mt-4">
                    By {blog.blogWrittenBy}{" "}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination hidden for now */}
          {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          /> */}
        </>
      ) : (
        <div className="py-8 text-center text-neutral3">
          No blogs available for the selected category.
        </div>
      )}
    </div>
  );
}

import React, { useEffect } from "react";
import SectionTitle from "../shared/SectionTitle";
import BackButton from "../shared/BackButton";
import { useParams } from "react-router-dom";
import ServicesDetailsData from "../../data/ServicesDetails";
import WhyChooseUs from "../../assets/services/details/why-choose-us.svg";
import StripeOne from "../../assets/services/details/stripe-one.svg";
import StripeTwo from "../../assets/services/details/stripe-two.svg";

const ServicesDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { id } = useParams();
  // const serviceId = parseInt(id, 10) - 1; // Convert id to integer and adjust for zero-indexed array
   
  const filteredServiceData = ServicesDetailsData.find(service=>service.id==id);
  return (
    <div className="relative">
      <BackButton className="mt-8" navigateTo="/services" />
      <SectionTitle sectionText="Services" className="flex justify-center" />
      {filteredServiceData && (
        <div key={filteredServiceData.id}>
          <h1 className="text-[20px] font-semibold md:text-[40px] mt-4 text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            {filteredServiceData.serviceHeader}
          </h1>
          <div className="p-4 flex flex-col md:flex md:flex-row mt-12 justify-between items-center">
            <div className="md:w-[511px] p-4">
              <h1 className="sm:text-sm sm:mt-2 text-white font-semibold md:text-[28px] text-left">
                Service Overview
              </h1>
              <p className="text-sm mt-4 text-white font-normal md:text-lg text-left">
                {filteredServiceData.content}
              </p>
            </div>
            <div className="sm:p-6 mt-4 md:w-1/2">
              <img
                src={filteredServiceData.image}
                alt={filteredServiceData.serviceHeader}
              />
            </div>
          </div>
          <div className="mt-12">
            <h1 className="sm:text-sm mt-4 text-white font-semibold md:text-[28px] text-left">
              Key Features
            </h1>
            {filteredServiceData.keyFeatures &&
              filteredServiceData.keyFeatures.feature.map((feature) => (
                <p
                  className="sm:text-sm text-white md:text-lg font-normal mt-6 text-left"
                  key={feature}
                >
                  {feature}
                </p>
              ))}
          </div>
          <div className="mt-12">
            <h1 className="sm:text-sm font-semibold text-sm md:text-[28px] text-white text-left">
              Infrastructure
            </h1>
            <p className="sm:text-xs mt-2 text-white font-normal md:text-lg text-left">
              {filteredServiceData.infrastructure?.title}
            </p>
            {filteredServiceData.infrastructure?.points &&
              filteredServiceData.infrastructure.points.map((point, index) => (
                <p
                  className="text-white text-lg font-normal mt-6 text-left"
                  key={index}
                >
                  {point}
                </p>
              ))}
            <div className="sm:flex sm:flex-col md:flex md:flex-row justify-between gap-8 mt-8">
              <div className="md:w-1/2 gradient-border border rounded-2xl p-4">
                <h1 className="text-lg text-left mt-2 text-white font-semibold md:text-[28px]">
                  {filteredServiceData.techStacks?.title}
                </h1>
                <ul className="mt-2 md:mt-2">
                  {filteredServiceData.techStacks?.array &&
                    filteredServiceData.techStacks.array.map(
                      (techstack, index) => (
                        <li
                          className="text-xs mt-2 ml-3 text-white font-normal md:text-lg text-left list-disc"
                          key={index}
                        >
                          {techstack}
                        </li>
                      )
                    )}
                </ul>
              </div>
              <div
                className="mt-6 md:w-1/2 md:mt-0 gradient-border p-4 rounded-2xl"
                style={{ borderRadius: "10px" }}
              >
                <h1 className="text-lg text-left mt-2 text-white font-semibold md:text-[28px]">
                  {filteredServiceData.developmentTechniques?.title}
                </h1>
                <ol>
                  {filteredServiceData.developmentTechniques?.array &&
                    filteredServiceData.developmentTechniques.array.map(
                      (developmenttechnique, index) => (
                        <li
                          className="text-sm mt-2 text-white font-normal md:text-lg text-left"
                          key={index}
                        >
                          {developmenttechnique}
                        </li>
                      )
                    )}
                </ol>
              </div>
            </div>
          </div>
          <div className="bg-gray82 p-4 mt-12 mb-8">
            <h1 className="text-sm text-white font-semibold md:text-[28px] mt-4 text-left">
              {filteredServiceData.developmentCycle?.title}
            </h1>
            <ol className="text-xs mt-4 text-left text-white md:text-lg">
              {filteredServiceData.developmentCycle?.array &&
                filteredServiceData.developmentCycle.array.map(
                  (devcycle, index) => <li key={index}>{devcycle}</li>
                )}
            </ol>
          </div>
          {/* Why Choose US */}
          <div className="px-0 py-6 md:p-6 relative">
            <h1 className="text-white text-left text-[28px] font-semibold">
              Why Choose Us
            </h1>
            <div className="mt-4 md:w-[1252px] md:h-[622px] md:flex md:flex-row items-center justify-center relative">
              <div className="md:h-[622px] md:w-2/5">
                <img
                  src={WhyChooseUs}
                  alt="why-choose-us"
                  className="md:h-[622px]"
                />
              </div>
              <div className="md:h-[590px] md:w-3/5 bg-gray82 rounded-r-xl relative p-4 md:p-0">
                <ul>
                  {filteredServiceData.whyChooseUs?.point &&
                    filteredServiceData.whyChooseUs.point.map((why, index) => (
                      <li
                        className="text-xs md:text-lg text-white text-left ml-4 mt-4"
                        key={index}
                      >
                        {why}
                      </li>
                    ))}
                </ul>
                {/* Background Stripes */}
                <img
                  src={StripeOne}
                  alt="Stripe One"
                  className="hidden md:block md:absolute top-0 right-0 transform -translate-x -translate-y-1/4 -z-10 overflow-x-hidden"
                />
                <img
                  src={StripeTwo}
                  alt="Stripe Two"
                  className="hidden md:block md:absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 -z-10 overflow-x-hidden"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesDetails;

import AboutUs from "../components/about-us/AboutUs";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | About Us</title>
        <meta
          name="description"
          content="Founded in 2014, Visibla Solutions is a software development company. We are firm believers in quality and perfection."
        />
      </Helmet>
      <AboutUs />
    </>
  );
}

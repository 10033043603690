import AIAndMLDevelopment from "../../assets/services/ai-and-ml-development.svg";
import CustomSoftwareDevelopment from "../../assets/services/custom-software-development.svg";
import TalentSourcing from "../../assets/services/talent-sourcing.svg";
import WebAndMobileDevelopment from "../../assets/services/web-and-mobile-development.svg";
import { useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import { Link } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-10  breakthrough-gradient">
      {/* Talk to our Experts */}
      <div className="mt-16">
        <h1 className="text-[20px] md:text-[40px] font-semibold text-center text-white">
          Breakthrough Innovation, Delivered Faster
        </h1>
        <p className="text-neutral3 font-normal text-lg text-center mt-4">
          In Business, speed is everything. We transform existing solutions and
          build new ones, all within your timeline.
        </p>

        <div
          onClick={() => {
            navigate("/contact-us");
          }}
          className="mt-8 flex justify-center"
        >
          <Button buttonText="Talk to our experts" />
        </div>
      </div>
      <h1 className="text-[20px] md:text-[40px] text-white font-semibold mt-12 text-center">
        Everything you need, right here!
      </h1>
      <div className="flex reakthrough-gradient overflow-x-auto md:overflow-hidden md:justify-center items-start gap-4 mt-8 ml-2">
        <div className="flex flex-col items-start md:ml-12">
          <div className="w-24 h-24 md:w-40 md:h-40 rounded-full bg-gray18 flex justify-center items-center">
            <div className="relative">
              <img
                src={CustomSoftwareDevelopment}
                alt="Custom Software"
                loading="lazy"
                className=""
              />
            </div>
          </div>
          <p className="text-[14px] md:text-[28px] font-semibold text-white mt-10 md:leading-8 text-left">
            Custom Software Development
          </p>
          <p className="text-neutral3 font-normal text-xs md:text-lg mt-6 text-left">
            We specialize in developing custom software and mobile applications,
            offering software development services.
          </p>
          <div className="text-left w-[160px] md:w-[302px]">
            <Link to="/services/custom-software-development">
              <Button buttonText="Read more" className="mt-4" />
            </Link>
          </div>
        </div>

        <div className="flex flex-col items-start">
          <div className="w-24 h-24 md:w-40 md:h-40 rounded-full bg-gray18 flex justify-center items-center">
            <div className="relative">
              <img
                src={WebAndMobileDevelopment}
                alt="Web Development"
                loading="lazy"
              />
            </div>
          </div>
          <p className="text-[14px] md:text-[28px] font-semibold text-white mt-10 md:leading-8 text-left">
            Web & Mobile <br className="hidden md:block" /> App Development
          </p>
          <p className="text-neutral3 font-normal text-xs md:text-lg mt-6 text-left">
            We specialize in developing custom software and mobile applications,
            offering software development services.
          </p>
          <div className="text-left w-[160px] md:w-[302px]">
            <Link to="/services/web-app-development">
              <Button buttonText="Read more" className="mt-4" />
            </Link>
          </div>
        </div>

        <div className="flex flex-col items-start">
          <div className="w-24 h-24 md:w-40 md:h-40 rounded-full bg-gray18 flex justify-center items-center">
            <div className="relative">
              <img
                src={AIAndMLDevelopment}
                alt="AI & ML Development"
                loading="lazy"
              />
            </div>
          </div>
          <p className="text-[14px] md:text-[28px] font-semibold text-white mt-10 md:leading-8 text-left">
            AI & ML <br className="hidden md:block" /> Services
          </p>
          <p className="text-neutral3 font-normal text-xs md:text-lg mt-6 text-left">
            We specialize in developing custom software and mobile applications,
            offering software development services.
          </p>
          <div className="text-left w-[160px] md:w-[302px]">
            <Link to="/services/ai-and-ml-services">
              <Button buttonText="Read more" className="mt-4" />
            </Link>
          </div>
        </div>

        <div className="flex flex-col items-start">
          <div className="w-24 h-24 md:w-40 md:h-40 rounded-full bg-gray18 flex justify-center items-center">
            <div className="relative">
              <img src={TalentSourcing} alt="Talent Sourcing" loading="lazy" />
            </div>
          </div>
          <p className="text-[14px] md:text-[28px] font-semibold text-white mt-10 md:leading-8 text-left">
            Talent <br className="hidden md:block" /> Resourcing
          </p>
          <p className="text-neutral3 font-normal text-xs md:text-lg mt-6 text-left">
            We specialize in developing custom software and mobile applications,
            offering software development services.
          </p>
          <div className="text-left w-[160px] md:w-[302px] mb-8 md:mb-0">
            <Link to="/services/talent-resourcing">
              <Button buttonText="Read more" className="mt-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

const SectionTitle = ({
  sectionText = "Section title",
  className = "",
  innerDivStyle = "",
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      <div
        className={`px-4 py-2 bg-gray11 text-sectionText text-center text-xs md:text-base flex justify-center items-center h-[44px] mx-auto ${innerDivStyle}`}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {sectionText}
      </div>
    </div>
  );
};

export default SectionTitle;

import React from "react";
import TechnologyPartners from "./TechnologyPartners";
import QuoteForm from "./QuoteForm";
import ReactTypingEffect from "react-typing-effect";
import Button from "../shared/Button";
import HomeHeroImage from "../../assets/hero/hero.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="md:flex md:flow-row justify-between items-top mt-6">
      <div className="text-left w-full md:w-4/6">
        <p className="text-[24px] md:text-6xl md: text-wrap font-semibold text-white mt-6 md:leading-[73.14px]">
          Your one-
          {/* <br className="hidden md:inline" /> */}
          stop <br /> destination for <br />
          <span className="text-secondary">
            <ReactTypingEffect
              text={[
                "Web Development",
                "App Development",
                "Software Development",
              ]}
              speed={150}
              eraseSpeed={50}
              eraseDelay={100}
              typingDelay={10}
            />
          </span>
        </p>
        <p className="text-white md:text-neutral3 font-normal text-sm md:text-lg mt-6">
          Accelerate business growth through the best software, apps and website
          developers in Asia
        </p>
        <div className="md:hidden mt-8">
          <Link to="/contact-us">
            <Button buttonText="Contact Us" />
          </Link>
        </div>
        <div className="hidden md:inline">
          <TechnologyPartners />
        </div>
      </div>
      <div className="hidden md:inline">
        <QuoteForm />
      </div>
      <div className="md:hidden mt-10">
        <img src={HomeHeroImage} alt="hero" />
      </div>
    </div>
  );
};

export default Hero;

import Careers from "../components/careers/Careers";
import { Helmet } from "react-helmet";

export default function CareersPage() {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Careers</title>
        <meta
          name="description"
          content="Join us in our mission: Explore professions in design, marketing, sales, software development, and many other fields. Apply right now."
        />
      </Helmet>
      <Careers />
    </>
  );
}

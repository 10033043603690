import { useState } from "react";
import axios from "axios";
import PhoneNumber from "../shared/PhoneNumber";

const QuoteForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonText, setButtonText] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const submitEnquiry = async () => {
    for (const key in formData) {
      if (formData[key] === "" || formData[key] === null) {
        setButtonText("Missing Fields!");
        setTimeout(() => {
          setButtonText(null);
        }, 2000);
        return;
      }
    }
    if (phoneNumber.length < 8) {
      alert("Missing fields.");
      return;
    }

    try {
      setButtonText("Loading...");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submitEnquiry`,
        {
          ...formData,
          contactNumber: phoneNumber,
          projectDescLink: null,
          enquiryCategoryId: null,
        }
      );
      if (response.data.meta.status) {
        setButtonText("Submitted Successfully!");
        setTimeout(() => {
          setButtonText(null);
        }, 3000);
        setFormData({
          name: "",
          email: "",

          message: "",
        });
        setPhoneNumber("");
      } else {
        alert("Error requesting Quote.");
        setButtonText(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-[460px] w-[426px] bg-gray97  bg-opacity-20 z-2 rounded-2xl mt-4  border-[1px] border-gray-400 backdrop-filter backdrop-blur-sm">
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        className="mt-8 block px-3 py-2 bg-gray-800 bg-opacity-50 rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 w-[394px] h-[60px] placeholder-white text-white "
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        className="mt-8 block px-3 py-2 bg-gray-800 bg-opacity-50 rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 w-[394px] h-[60px] placeholder-white text-white "
        required
      />
      <PhoneNumber phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
      <input
        type="message"
        name="message"
        placeholder="Your Message"
        value={formData.message}
        onChange={handleChange}
        className="mt-8 block px-3 py-2 bg-gray-800 bg-opacity-50 rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 w-[394px] h-[60px] placeholder-white text-white "
        required
      />
      <button
        onClick={() => {
          submitEnquiry();
        }}
        className="mt-4 w-80 button-shade-tl h-[60px] bg-black text-secondary font-semibold rounded-xl"
      >
        {buttonText ? buttonText : "Get Free Quote"}
      </button>
    </div>
  );
};

export default QuoteForm;

import React, { useState } from "react";
import Country from "../../data/Country"; // Assuming your Country.json file is in the same directory
import CountryDropdown from "../shared/CustomDropdown"; // Adjust the import path as necessary

const PhoneNumberInput = ({phoneNumber,setPhoneNumber}) => {
  const [selectedCountry, setSelectedCountry] = useState(Country.countries[0]); // Default country to the first one in the list


  const handleCountryChange = (code) => {
    setSelectedCountry(
      Country.countries.find((country) => country.code === code)
    );
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div className="w-[394px]  h-[60px] flex rounded-lg  focus-within:border-blue-500 mt-4">
      <CountryDropdown
        countries={Country.countries}
        selectedCountry={selectedCountry}
        onChange={handleCountryChange}
      />
      <input
        className="block px-3 py-2 bg-gray-800 bg-opacity-50 rounded-e-md text-sm shadow-sm focus:outline-none focus:ring-1 w-[300px] h-[60px] placeholder-white text-white "
        type="tel"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
    </div>
  );
};

export default PhoneNumberInput;

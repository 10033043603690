import React, { useState } from "react";
import Logo from "../../assets/visibla-logo.svg";
import { Link, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="text-white flex items-center justify-between px-4 md:px-8 lg:px-16 py-0 mt-2 relative z-50">
      <div className="flex items-center">
        <Link to="/">
          <img
            src={Logo}
            alt="Visibla Solutions"
            className="h-20 mr-4 w-40"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="hidden md:flex items-center space-x-4">
        <nav className="flex space-x-6">
          <Link
            to="/services"
            className={`hover:text-gray-300 ${
              location.pathname === "/services" &&
              "text-secondary hover:text-secondary"
            }`}
          >
            Services
          </Link>
          <Link
            to="/industries"
            className={`hover:text-gray-300 ${
              location.pathname === "/industries" &&
              "text-secondary hover:text-secondary"
            }`}
          >
            Industries
          </Link>
          <Link
            to="/case-study"
            className={`hover:text-gray-300 whitespace-nowrap ${
              location.pathname === "/case-study" &&
              "text-secondary hover:text-secondary"
            }`}
          >
            Case Studies
          </Link>
          <Link
            to="/about-us"
            className={`hover:text-gray-300 ${
              location.pathname === "/about-us" &&
              "text-secondary hover:text-secondary"
            }`}
          >
            About
          </Link>
        </nav>
        <Link
          to="/careers"
          className={`hover:text-gray-300 ${
            location.pathname === "/careers" &&
            "text-secondary hover:text-secondary"
          }`}
        >
          Careers
        </Link>
        <Link
          to="/contact-us"
          className={`whitespace-nowrap bg-gray-700 py-1 px-4 rounded-3xl hover:bg-gray-600 ${
            location.pathname === "/contact-us" &&
            "bg-secondary hover:bg-secondary"
          }`}
        >
          Contact us
        </Link>
      </div>

      {/* Mobile hamburger menu */}
      <div className="md:hidden">
        <button onClick={toggleMenu}>
          {isOpen ? <FaTimes size={20} /> : <GiHamburgerMenu size={20} />}
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-20 left-0 w-full h-screen bg-black bg-opacity-100 flex flex-col items-center space-y-6 pt-6 md:hidden z-50">
          <nav className="flex flex-col items-center space-y-6">
            <Link
              to="/services"
              className={`hover:text-gray-300 ${
                location.pathname === "/services" &&
                "text-secondary hover:text-secondary"
              }`}
              onClick={toggleMenu}
            >
              Services
            </Link>
            <Link
              to="/industries"
              className={`hover:text-gray-300 ${
                location.pathname === "/industries" &&
                "text-secondary hover:text-secondary"
              }`}
              onClick={toggleMenu}
            >
              Industries
            </Link>
            <Link
              to="/case-study"
              className={`hover:text-gray-300 whitespace-nowrap ${
                location.pathname === "/case-study" &&
                "text-secondary hover:text-secondary"
              }`}
              onClick={toggleMenu}
            >
              Case Studies
            </Link>
            <Link
              to="/about-us"
              className={`hover:text-gray-300 ${
                location.pathname === "/about-us" &&
                "text-secondary hover:text-secondary"
              }`}
              onClick={toggleMenu}
            >
              About
            </Link>
            <Link
              to="/careers"
              className={`hover:text-gray-300 ${
                location.pathname === "/careers" &&
                "text-secondary hover:text-secondary"
              }`}
              onClick={toggleMenu}
            >
              Careers
            </Link>
            <Link
              to="/contact-us"
              className={`whitespace-nowrap bg-gray-700 py-1 px-4 rounded-3xl hover:bg-gray-600 ${
                location.pathname === "/contact-us" &&
                "bg-secondary hover:bg-secondary"
              }`}
              onClick={toggleMenu}
            >
              Contact us
            </Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;

import React, { useState } from "react";

const phases = [
  {
    header: "Phases",
    steps: {
      tasks: ["Phase 1", "Phase 2", "Phase 3", "Phase 4"],
    },
    isIndexSet: false,
  },
  {
    header: "Initiation",
    steps: {
      tasks: [
        "Define Objectives and Scope",
        "Identify Stakeholders",
        "Gather Client Requirements",
        "Feasibility Study and Initial Risk Assessment",
      ],
    },
    isIndexSet: false,
  },
  {
    header: "Planning",
    steps: {
      tasks: [
        "Develop Project Plan",
        "Create Wireframes",
        "Define Technical Requirements",
        "Risk Management Planning",
      ],
    },
    isIndexSet: false,
  },
  {
    header: "Design",
    steps: {
      tasks: [
        "Develop Website Design",
        "Customize Branding",
        "Conduct Usability Testing",
        "Design Refinement",
      ],
    },
    isIndexSet: false,
  },
  {
    header: "Development",
    steps: {
      tasks: [
        "Build Website Infrastructure",
        "Develop Functionalities",
        "Integrate Payment Gateway",
        "Code Review and Optimization",
      ],
    },
    isIndexSet: false,
  },
  {
    header: "Testing",
    steps: {
      tasks: [
        "Conduct Thorough Testing",
        "Test Compatibility and Performance",
        "Address Identified Issues",
        "Final Testing and Approval",
      ],
    },
    isIndexSet: false,
  },
  {
    header: "Deployment",
    steps: {
      tasks: [
        "Prepare for Deployment",
        "Migrate Data and Configure",
        "Go Live and Monitor",
        "Initial Support",
      ],
    },
    isIndexSet: false,
  },
  {
    header: "Post Deployment",
    steps: {
      tasks: [
        "Provide Client Training",
        "Monitor Performance",
        "Implement Optimizations",
        "Maintenance and Support",
      ],
    },
    isIndexSet: false,
  },
];

const Planner = () => {
  const [hoveredTask, setHoveredTask] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (taskIndex, rowIndex) => {
    setHoveredTask(taskIndex);
    setHoveredRow(rowIndex);
  };

  const handleMouseLeave = () => {
    setHoveredTask(null);
    setHoveredRow(null);
  };

  const plannerPhases = ["Phase 1", "Phase 2", "Phase 3", "Phase 4"];

  return (
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
        <div className="grid grid-cols-5 gap-x-2 gap-y-2 md:gap-x-4 md:gap-y-4 min-w-max -ml-16 md:ml-0 lg:-ml-8">
          {phases.map((phase, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className="col-span-1 flex justify-center items-center gap-4">
                <div
                  className={`min-w-full w-max h-[70px] text-xs md:text-lg text-center flex items-center justify-center ${
                    phase.header === "Phases"
                      ? "text-black"
                      : hoveredRow === rowIndex
                      ? "text-mediumBlue"
                      : "text-white"
                  }`}
                >
                  {phase.header}
                </div>
              </div>
              {phase.steps.tasks.map((task, taskIndex) => (
                <div
                  className="col-span-1 flex justify-center items-center"
                  key={taskIndex}
                >
                  <div
                    className={`md:ml-0 min-w-full w-max md:w-[190px] h-[70px] text-white text-center text-xs md:text-base flex items-center justify-center rounded-lg py-2 gap-4 ${
                      plannerPhases.includes(task)
                        ? "bg-black font-bold text-[22px]"
                        : hoveredTask === `${rowIndex}-${taskIndex}`
                        ? "bg-mediumBlue"
                        : "bg-gray12"
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${rowIndex}-${taskIndex}`, rowIndex)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {task}
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Planner;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReadMore from "../shared/ReadMore";
import BackButton from "../shared/BackButton";

export default function NewsDetails() {
  const { newsId } = useParams();
  const [newsDetails, setNewsDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getNewsDetails`,
          { newsId }
        );
        setNewsDetails(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news details:", error);
        setLoading(false);
      }
    };

    fetchNewsDetails();
  }, [newsId]);

  if (loading) {
    return <div className="py-8 text-left">Loading...</div>;
  }

  const newsImageUrl = newsDetails.newsImage;

  // Function to format text with line breaks
  const formatText = (text) => {
    return text.replace(/\n/g, "<br />");
  };

  return (
    <div>
      <BackButton navigateTo="/news" className="mb-12 mt-4 md:mt-6 md:mb-2 " />
      <div className="flex flex-col text-white text-left">
        <div className="relative flex flex-col justify-center items-center text-center text-white h-[30vh] md:h-[50vh] lg:h-[82vh] w-full mt-4">
          <div
            className="absolute inset-0 bg-cover bg-center opacity-50"
            style={{
              backgroundImage: `url(${newsImageUrl})`,
            }}
          ></div>
          <div className="relative z-10">
            <h1 className="text-2xl md:text-[40px] font-semibold">
              {newsDetails.newsTitle}
            </h1>
          </div>
        </div>

        <main>
          <section className="mb-10">
            <p
              className="flex font-normal text-[18px] mt-16"
              dangerouslySetInnerHTML={{
                __html: formatText(newsDetails.newsDescription),
              }}
            ></p>
          </section>

          <ReadMore
            items={newsDetails.relatedNews.map((news) => ({
              id: news.newsId,
              image: news.newsImage,
              categoryName: news.newsCategoryName,
              title: news.newsTitle,
              description: news.newsDescription,
            }))}
            title="Read more news like this"
            linkPrefix="news-details"
          />
        </main>
      </div>
    </div>
  );
}

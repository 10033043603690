import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage.jsx";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import FAQsPage from "./pages/FaqsPage.jsx";
import Layout from "./components/shared/Layout.jsx";
import ContactUsPage from "./pages/ContactUsPage.jsx";
import CareersPage from "./pages/CareersPage";
import AboutUsPage from "./pages/AboutUsPage";
import CaseStudyPage from "./pages/CaseStudyPage.jsx";
import CaseStudyDetails from "./components/case-study/CaseStudyDetails.jsx";
import BlogDetailsPage from "./pages/BlogDetailsPage.jsx";
import NewsDetailsPage from "./pages/NewsDetailsPage";
import IndustriesPage from "./pages/IndustriesPage";
import BlogsPage from "./pages/BlogsPage";
import NewsPage from "./pages/NewsPage";
import ServicesPage from "./pages/ServicesPage.jsx";
import ServicesDetailsPage from "./pages/ServicesDetailsPage.jsx";

function App() {
  return (
    <Router>
      <Layout>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/case-study" element={<CaseStudyPage />} />
            <Route
              path="/case-study/:id"
              element={<CaseStudyDetails />}
            />
            <Route path="/blog-details/:blogId" element={<BlogDetailsPage />} />
            <Route path="/news-details/:newsId" element={<NewsDetailsPage />} />
            <Route path="/industries" element={<IndustriesPage />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route
              path="/services/:id"
              element={<ServicesDetailsPage />}
            />
          </Routes>
        </div>
      </Layout>
    </Router>
  );
}

export default App;

const Country = {
  countries: [
    {
      name: "India",
      code: "IN",
      phone_code: "+91",
      flag: "https://flagcdn.com/in.svg",
    },
    {
      name: "Afghanistan",
      code: "AF",
      phone_code: "+93",
      flag: "https://flagcdn.com/af.svg",
    },
    {
      name: "Albania",
      code: "AL",
      phone_code: "+355",
      flag: "https://flagcdn.com/al.svg",
    },
    {
      name: "Algeria",
      code: "DZ",
      phone_code: "+213",
      flag: "https://flagcdn.com/dz.svg",
    },
    {
      name: "Andorra",
      code: "AD",
      phone_code: "+376",
      flag: "https://flagcdn.com/ad.svg",
    },
    {
      name: "Angola",
      code: "AO",
      phone_code: "+244",
      flag: "https://flagcdn.com/ao.svg",
    },
    {
      name: "Antigua and Barbuda",
      code: "AG",
      phone_code: "+1-268",
      flag: "https://flagcdn.com/ag.svg",
    },
    {
      name: "Argentina",
      code: "AR",
      phone_code: "+54",
      flag: "https://flagcdn.com/ar.svg",
    },
    {
      name: "Armenia",
      code: "AM",
      phone_code: "+374",
      flag: "https://flagcdn.com/am.svg",
    },
    {
      name: "Australia",
      code: "AU",
      phone_code: "+61",
      flag: "https://flagcdn.com/au.svg",
    },
    {
      name: "Austria",
      code: "AT",
      phone_code: "+43",
      flag: "https://flagcdn.com/at.svg",
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      phone_code: "+994",
      flag: "https://flagcdn.com/az.svg",
    },
    {
      name: "Bahamas",
      code: "BS",
      phone_code: "+1-242",
      flag: "https://flagcdn.com/bs.svg",
    },
    {
      name: "Bahrain",
      code: "BH",
      phone_code: "+973",
      flag: "https://flagcdn.com/bh.svg",
    },
    {
      name: "Bangladesh",
      code: "BD",
      phone_code: "+880",
      flag: "https://flagcdn.com/bd.svg",
    },
    {
      name: "Barbados",
      code: "BB",
      phone_code: "+1-246",
      flag: "https://flagcdn.com/bb.svg",
    },
    {
      name: "Belarus",
      code: "BY",
      phone_code: "+375",
      flag: "https://flagcdn.com/by.svg",
    },
    {
      name: "Belgium",
      code: "BE",
      phone_code: "+32",
      flag: "https://flagcdn.com/be.svg",
    },
    {
      name: "Belize",
      code: "BZ",
      phone_code: "+501",
      flag: "https://flagcdn.com/bz.svg",
    },
    {
      name: "Benin",
      code: "BJ",
      phone_code: "+229",
      flag: "https://flagcdn.com/bj.svg",
    },
    {
      name: "Bhutan",
      code: "BT",
      phone_code: "+975",
      flag: "https://flagcdn.com/bt.svg",
    },
    {
      name: "Bolivia",
      code: "BO",
      phone_code: "+591",
      flag: "https://flagcdn.com/bo.svg",
    },
    {
      name: "Bosnia and Herzegovina",
      code: "BA",
      phone_code: "+387",
      flag: "https://flagcdn.com/ba.svg",
    },
    {
      name: "Botswana",
      code: "BW",
      phone_code: "+267",
      flag: "https://flagcdn.com/bw.svg",
    },
    {
      name: "Brazil",
      code: "BR",
      phone_code: "+55",
      flag: "https://flagcdn.com/br.svg",
    },
    {
      name: "Brunei",
      code: "BN",
      phone_code: "+673",
      flag: "https://flagcdn.com/bn.svg",
    },
    {
      name: "Bulgaria",
      code: "BG",
      phone_code: "+359",
      flag: "https://flagcdn.com/bg.svg",
    },
    {
      name: "Burkina Faso",
      code: "BF",
      phone_code: "+226",
      flag: "https://flagcdn.com/bf.svg",
    },
    {
      name: "Burundi",
      code: "BI",
      phone_code: "+257",
      flag: "https://flagcdn.com/bi.svg",
    },
    {
      name: "Cabo Verde",
      code: "CV",
      phone_code: "+238",
      flag: "https://flagcdn.com/cv.svg",
    },
    {
      name: "Cambodia",
      code: "KH",
      phone_code: "+855",
      flag: "https://flagcdn.com/kh.svg",
    },
    {
      name: "Cameroon",
      code: "CM",
      phone_code: "+237",
      flag: "https://flagcdn.com/cm.svg",
    },
    {
      name: "Canada",
      code: "CA",
      phone_code: "+1",
      flag: "https://flagcdn.com/ca.svg",
    },
    {
      name: "Central African Republic",
      code: "CF",
      phone_code: "+236",
      flag: "https://flagcdn.com/cf.svg",
    },
    {
      name: "Chad",
      code: "TD",
      phone_code: "+235",
      flag: "https://flagcdn.com/td.svg",
    },
    {
      name: "Chile",
      code: "CL",
      phone_code: "+56",
      flag: "https://flagcdn.com/cl.svg",
    },
    {
      name: "China",
      code: "CN",
      phone_code: "+86",
      flag: "https://flagcdn.com/cn.svg",
    },
    {
      name: "Colombia",
      code: "CO",
      phone_code: "+57",
      flag: "https://flagcdn.com/co.svg",
    },
    {
      name: "Comoros",
      code: "KM",
      phone_code: "+269",
      flag: "https://flagcdn.com/km.svg",
    },
    {
      name: "Congo (Congo-Brazzaville)",
      code: "CG",
      phone_code: "+242",
      flag: "https://flagcdn.com/cg.svg",
    },
    {
      name: "Congo (DRC)",
      code: "CD",
      phone_code: "+243",
      flag: "https://flagcdn.com/cd.svg",
    },
    {
      name: "Costa Rica",
      code: "CR",
      phone_code: "+506",
      flag: "https://flagcdn.com/cr.svg",
    },
    {
      name: "Croatia",
      code: "HR",
      phone_code: "+385",
      flag: "https://flagcdn.com/hr.svg",
    },
    {
      name: "Cuba",
      code: "CU",
      phone_code: "+53",
      flag: "https://flagcdn.com/cu.svg",
    },
    {
      name: "Cyprus",
      code: "CY",
      phone_code: "+357",
      flag: "https://flagcdn.com/cy.svg",
    },
    {
      name: "Czechia",
      code: "CZ",
      phone_code: "+420",
      flag: "https://flagcdn.com/cz.svg",
    },
    {
      name: "Denmark",
      code: "DK",
      phone_code: "+45",
      flag: "https://flagcdn.com/dk.svg",
    },
    {
      name: "Djibouti",
      code: "DJ",
      phone_code: "+253",
      flag: "https://flagcdn.com/dj.svg",
    },
    {
      name: "Dominica",
      code: "DM",
      phone_code: "+1-767",
      flag: "https://flagcdn.com/dm.svg",
    },
    {
      name: "Dominican Republic",
      code: "DO",
      phone_code: "+1-809",
      flag: "https://flagcdn.com/do.svg",
    },
    {
      name: "Ecuador",
      code: "EC",
      phone_code: "+593",
      flag: "https://flagcdn.com/ec.svg",
    },
    {
      name: "Egypt",
      code: "EG",
      phone_code: "+20",
      flag: "https://flagcdn.com/eg.svg",
    },
    {
      name: "El Salvador",
      code: "SV",
      phone_code: "+503",
      flag: "https://flagcdn.com/sv.svg",
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      phone_code: "+240",
      flag: "https://flagcdn.com/gq.svg",
    },
    {
      name: "Eritrea",
      code: "ER",
      phone_code: "+291",
      flag: "https://flagcdn.com/er.svg",
    },
    {
      name: "Estonia",
      code: "EE",
      phone_code: "+372",
      flag: "https://flagcdn.com/ee.svg",
    },
    {
      name: "Eswatini",
      code: "SZ",
      phone_code: "+268",
      flag: "https://flagcdn.com/sz.svg",
    },
    {
      name: "Ethiopia",
      code: "ET",
      phone_code: "+251",
      flag: "https://flagcdn.com/et.svg",
    },
    {
      name: "Fiji",
      code: "FJ",
      phone_code: "+679",
      flag: "https://flagcdn.com/fj.svg",
    },
    {
      name: "Finland",
      code: "FI",
      phone_code: "+358",
      flag: "https://flagcdn.com/fi.svg",
    },
    {
      name: "France",
      code: "FR",
      phone_code: "+33",
      flag: "https://flagcdn.com/fr.svg",
    },
    {
      name: "Gabon",
      code: "GA",
      phone_code: "+241",
      flag: "https://flagcdn.com/ga.svg",
    },
    {
      name: "Gambia",
      code: "GM",
      phone_code: "+220",
      flag: "https://flagcdn.com/gm.svg",
    },
    {
      name: "Georgia",
      code: "GE",
      phone_code: "+995",
      flag: "https://flagcdn.com/ge.svg",
    },
    {
      name: "Germany",
      code: "DE",
      phone_code: "+49",
      flag: "https://flagcdn.com/de.svg",
    },
    {
      name: "Ghana",
      code: "GH",
      phone_code: "+233",
      flag: "https://flagcdn.com/gh.svg",
    },
    {
      name: "Greece",
      code: "GR",
      phone_code: "+30",
      flag: "https://flagcdn.com/gr.svg",
    },
    {
      name: "Grenada",
      code: "GD",
      phone_code: "+1-473",
      flag: "https://flagcdn.com/gd.svg",
    },
    {
      name: "Guatemala",
      code: "GT",
      phone_code: "+502",
      flag: "https://flagcdn.com/gt.svg",
    },
    {
      name: "Guinea",
      code: "GN",
      phone_code: "+224",
      flag: "https://flagcdn.com/gn.svg",
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      phone_code: "+245",
      flag: "https://flagcdn.com/gw.svg",
    },
    {
      name: "Guyana",
      code: "GY",
      phone_code: "+592",
      flag: "https://flagcdn.com/gy.svg",
    },
    {
      name: "Haiti",
      code: "HT",
      phone_code: "+509",
      flag: "https://flagcdn.com/ht.svg",
    },
    {
      name: "Honduras",
      code: "HN",
      phone_code: "+504",
      flag: "https://flagcdn.com/hn.svg",
    },
    {
      name: "Hungary",
      code: "HU",
      phone_code: "+36",
      flag: "https://flagcdn.com/hu.svg",
    },
    {
      name: "Iceland",
      code: "IS",
      phone_code: "+354",
      flag: "https://flagcdn.com/is.svg",
    },
    {
      name: "Indonesia",
      code: "ID",
      phone_code: "+62",
      flag: "https://flagcdn.com/id.svg",
    },
    {
      name: "Iran",
      code: "IR",
      phone_code: "+98",
      flag: "https://flagcdn.com/ir.svg",
    },
    {
      name: "Iraq",
      code: "IQ",
      phone_code: "+964",
      flag: "https://flagcdn.com/iq.svg",
    },
    {
      name: "Ireland",
      code: "IE",
      phone_code: "+353",
      flag: "https://flagcdn.com/ie.svg",
    },
    {
      name: "Israel",
      code: "IL",
      phone_code: "+972",
      flag: "https://flagcdn.com/il.svg",
    },
    {
      name: "Italy",
      code: "IT",
      phone_code: "+39",
      flag: "https://flagcdn.com/it.svg",
    },
    {
      name: "Jamaica",
      code: "JM",
      phone_code: "+1-876",
      flag: "https://flagcdn.com/jm.svg",
    },
    {
      name: "Japan",
      code: "JP",
      phone_code: "+81",
      flag: "https://flagcdn.com/jp.svg",
    },
    {
      name: "Jordan",
      code: "JO",
      phone_code: "+962",
      flag: "https://flagcdn.com/jo.svg",
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      phone_code: "+7",
      flag: "https://flagcdn.com/kz.svg",
    },
    {
      name: "Kenya",
      code: "KE",
      phone_code: "+254",
      flag: "https://flagcdn.com/ke.svg",
    },
    {
      name: "Kiribati",
      code: "KI",
      phone_code: "+686",
      flag: "https://flagcdn.com/ki.svg",
    },
    {
      name: "Kuwait",
      code: "KW",
      phone_code: "+965",
      flag: "https://flagcdn.com/kw.svg",
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      phone_code: "+996",
      flag: "https://flagcdn.com/kg.svg",
    },
    {
      name: "Laos",
      code: "LA",
      phone_code: "+856",
      flag: "https://flagcdn.com/la.svg",
    },
    {
      name: "Latvia",
      code: "LV",
      phone_code: "+371",
      flag: "https://flagcdn.com/lv.svg",
    },
    {
      name: "Lebanon",
      code: "LB",
      phone_code: "+961",
      flag: "https://flagcdn.com/lb.svg",
    },
    {
      name: "Lesotho",
      code: "LS",
      phone_code: "+266",
      flag: "https://flagcdn.com/ls.svg",
    },
    {
      name: "Liberia",
      code: "LR",
      phone_code: "+231",
      flag: "https://flagcdn.com/lr.svg",
    },
    {
      name: "Libya",
      code: "LY",
      phone_code: "+218",
      flag: "https://flagcdn.com/ly.svg",
    },
    {
      name: "Liechtenstein",
      code: "LI",
      phone_code: "+423",
      flag: "https://flagcdn.com/li.svg",
    },
    {
      name: "Lithuania",
      code: "LT",
      phone_code: "+370",
      flag: "https://flagcdn.com/lt.svg",
    },
    {
      name: "Luxembourg",
      code: "LU",
      phone_code: "+352",
      flag: "https://flagcdn.com/lu.svg",
    },
    {
      name: "Madagascar",
      code: "MG",
      phone_code: "+261",
      flag: "https://flagcdn.com/mg.svg",
    },
    {
      name: "Malawi",
      code: "MW",
      phone_code: "+265",
      flag: "https://flagcdn.com/mw.svg",
    },
    {
      name: "Malaysia",
      code: "MY",
      phone_code: "+60",
      flag: "https://flagcdn.com/my.svg",
    },
    {
      name: "Maldives",
      code: "MV",
      phone_code: "+960",
      flag: "https://flagcdn.com/mv.svg",
    },
    {
      name: "Mali",
      code: "ML",
      phone_code: "+223",
      flag: "https://flagcdn.com/ml.svg",
    },
    {
      name: "Malta",
      code: "MT",
      phone_code: "+356",
      flag: "https://flagcdn.com/mt.svg",
    },
    {
      name: "Marshall Islands",
      code: "MH",
      phone_code: "+692",
      flag: "https://flagcdn.com/mh.svg",
    },
    {
      name: "Mauritania",
      code: "MR",
      phone_code: "+222",
      flag: "https://flagcdn.com/mr.svg",
    },
    {
      name: "Mauritius",
      code: "MU",
      phone_code: "+230",
      flag: "https://flagcdn.com/mu.svg",
    },
    {
      name: "Mexico",
      code: "MX",
      phone_code: "+52",
      flag: "https://flagcdn.com/mx.svg",
    },
    {
      name: "Micronesia",
      code: "FM",
      phone_code: "+691",
      flag: "https://flagcdn.com/fm.svg",
    },
    {
      name: "Moldova",
      code: "MD",
      phone_code: "+373",
      flag: "https://flagcdn.com/md.svg",
    },
    {
      name: "Monaco",
      code: "MC",
      phone_code: "+377",
      flag: "https://flagcdn.com/mc.svg",
    },
    {
      name: "Mongolia",
      code: "MN",
      phone_code: "+976",
      flag: "https://flagcdn.com/mn.svg",
    },
    {
      name: "Montenegro",
      code: "ME",
      phone_code: "+382",
      flag: "https://flagcdn.com/me.svg",
    },
    {
      name: "Morocco",
      code: "MA",
      phone_code: "+212",
      flag: "https://flagcdn.com/ma.svg",
    },
    {
      name: "Mozambique",
      code: "MZ",
      phone_code: "+258",
      flag: "https://flagcdn.com/mz.svg",
    },
    {
      name: "Myanmar",
      code: "MM",
      phone_code: "+95",
      flag: "https://flagcdn.com/mm.svg",
    },
    {
      name: "Namibia",
      code: "NA",
      phone_code: "+264",
      flag: "https://flagcdn.com/na.svg",
    },
    {
      name: "Nauru",
      code: "NR",
      phone_code: "+674",
      flag: "https://flagcdn.com/nr.svg",
    },
    {
      name: "Nepal",
      code: "NP",
      phone_code: "+977",
      flag: "https://flagcdn.com/np.svg",
    },
    {
      name: "Netherlands",
      code: "NL",
      phone_code: "+31",
      flag: "https://flagcdn.com/nl.svg",
    },
    {
      name: "New Zealand",
      code: "NZ",
      phone_code: "+64",
      flag: "https://flagcdn.com/nz.svg",
    },
    {
      name: "Nicaragua",
      code: "NI",
      phone_code: "+505",
      flag: "https://flagcdn.com/ni.svg",
    },
    {
      name: "Niger",
      code: "NE",
      phone_code: "+227",
      flag: "https://flagcdn.com/ne.svg",
    },
    {
      name: "Nigeria",
      code: "NG",
      phone_code: "+234",
      flag: "https://flagcdn.com/ng.svg",
    },
    {
      name: "North Korea",
      code: "KP",
      phone_code: "+850",
      flag: "https://flagcdn.com/kp.svg",
    },
    {
      name: "North Macedonia",
      code: "MK",
      phone_code: "+389",
      flag: "https://flagcdn.com/mk.svg",
    },
    {
      name: "Norway",
      code: "NO",
      phone_code: "+47",
      flag: "https://flagcdn.com/no.svg",
    },
    {
      name: "Oman",
      code: "OM",
      phone_code: "+968",
      flag: "https://flagcdn.com/om.svg",
    },
    {
      name: "Pakistan",
      code: "PK",
      phone_code: "+92",
      flag: "https://flagcdn.com/pk.svg",
    },
    {
      name: "Palau",
      code: "PW",
      phone_code: "+680",
      flag: "https://flagcdn.com/pw.svg",
    },
    {
      name: "Palestine",
      code: "PS",
      phone_code: "+970",
      flag: "https://flagcdn.com/ps.svg",
    },
    {
      name: "Panama",
      code: "PA",
      phone_code: "+507",
      flag: "https://flagcdn.com/pa.svg",
    },
    {
      name: "Papua New Guinea",
      code: "PG",
      phone_code: "+675",
      flag: "https://flagcdn.com/pg.svg",
    },
    {
      name: "Paraguay",
      code: "PY",
      phone_code: "+595",
      flag: "https://flagcdn.com/py.svg",
    },
    {
      name: "Peru",
      code: "PE",
      phone_code: "+51",
      flag: "https://flagcdn.com/pe.svg",
    },
    {
      name: "Philippines",
      code: "PH",
      phone_code: "+63",
      flag: "https://flagcdn.com/ph.svg",
    },
    {
      name: "Poland",
      code: "PL",
      phone_code: "+48",
      flag: "https://flagcdn.com/pl.svg",
    },
    {
      name: "Portugal",
      code: "PT",
      phone_code: "+351",
      flag: "https://flagcdn.com/pt.svg",
    },
    {
      name: "Qatar",
      code: "QA",
      phone_code: "+974",
      flag: "https://flagcdn.com/qa.svg",
    },
    {
      name: "Republic of the Congo",
      code: "CG",
      phone_code: "+242",
      flag: "https://flagcdn.com/cg.svg",
    },
    {
      name: "Romania",
      code: "RO",
      phone_code: "+40",
      flag: "https://flagcdn.com/ro.svg",
    },
    {
      name: "Russia",
      code: "RU",
      phone_code: "+7",
      flag: "https://flagcdn.com/ru.svg",
    },
    {
      name: "Rwanda",
      code: "RW",
      phone_code: "+250",
      flag: "https://flagcdn.com/rw.svg",
    },
    {
      name: "Saint Kitts and Nevis",
      code: "KN",
      phone_code: "+1-869",
      flag: "https://flagcdn.com/kn.svg",
    },
    {
      name: "Saint Lucia",
      code: "LC",
      phone_code: "+1-758",
      flag: "https://flagcdn.com/lc.svg",
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "VC",
      phone_code: "+1-784",
      flag: "https://flagcdn.com/vc.svg",
    },
    {
      name: "Samoa",
      code: "WS",
      phone_code: "+685",
      flag: "https://flagcdn.com/ws.svg",
    },
    {
      name: "San Marino",
      code: "SM",
      phone_code: "+378",
      flag: "https://flagcdn.com/sm.svg",
    },
    {
      name: "Sao Tome and Principe",
      code: "ST",
      phone_code: "+239",
      flag: "https://flagcdn.com/st.svg",
    },
    {
      name: "Saudi Arabia",
      code: "SA",
      phone_code: "+966",
      flag: "https://flagcdn.com/sa.svg",
    },
    {
      name: "Senegal",
      code: "SN",
      phone_code: "+221",
      flag: "https://flagcdn.com/sn.svg",
    },
    {
      name: "Serbia",
      code: "RS",
      phone_code: "+381",
      flag: "https://flagcdn.com/rs.svg",
    },
    {
      name: "Seychelles",
      code: "SC",
      phone_code: "+248",
      flag: "https://flagcdn.com/sc.svg",
    },
    {
      name: "Sierra Leone",
      code: "SL",
      phone_code: "+232",
      flag: "https://flagcdn.com/sl.svg",
    },
    {
      name: "Singapore",
      code: "SG",
      phone_code: "+65",
      flag: "https://flagcdn.com/sg.svg",
    },
    {
      name: "Slovakia",
      code: "SK",
      phone_code: "+421",
      flag: "https://flagcdn.com/sk.svg",
    },
    {
      name: "Slovenia",
      code: "SI",
      phone_code: "+386",
      flag: "https://flagcdn.com/si.svg",
    },
    {
      name: "Solomon Islands",
      code: "SB",
      phone_code: "+677",
      flag: "https://flagcdn.com/sb.svg",
    },
    {
      name: "Somalia",
      code: "SO",
      phone_code: "+252",
      flag: "https://flagcdn.com/so.svg",
    },
    {
      name: "South Africa",
      code: "ZA",
      phone_code: "+27",
      flag: "https://flagcdn.com/za.svg",
    },
    {
      name: "South Korea",
      code: "KR",
      phone_code: "+82",
      flag: "https://flagcdn.com/kr.svg",
    },
    {
      name: "South Sudan",
      code: "SS",
      phone_code: "+211",
      flag: "https://flagcdn.com/ss.svg",
    },
    {
      name: "Spain",
      code: "ES",
      phone_code: "+34",
      flag: "https://flagcdn.com/es.svg",
    },
    {
      name: "Sri Lanka",
      code: "LK",
      phone_code: "+94",
      flag: "https://flagcdn.com/lk.svg",
    },
    {
      name: "Sudan",
      code: "SD",
      phone_code: "+249",
      flag: "https://flagcdn.com/sd.svg",
    },
    {
      name: "Suriname",
      code: "SR",
      phone_code: "+597",
      flag: "https://flagcdn.com/sr.svg",
    },
    {
      name: "Sweden",
      code: "SE",
      phone_code: "+46",
      flag: "https://flagcdn.com/se.svg",
    },
    {
      name: "Switzerland",
      code: "CH",
      phone_code: "+41",
      flag: "https://flagcdn.com/ch.svg",
    },
    {
      name: "Syria",
      code: "SY",
      phone_code: "+963",
      flag: "https://flagcdn.com/sy.svg",
    },
    {
      name: "Taiwan",
      code: "TW",
      phone_code: "+886",
      flag: "https://flagcdn.com/tw.svg",
    },
    {
      name: "Tajikistan",
      code: "TJ",
      phone_code: "+992",
      flag: "https://flagcdn.com/tj.svg",
    },
    {
      name: "Tanzania",
      code: "TZ",
      phone_code: "+255",
      flag: "https://flagcdn.com/tz.svg",
    },
    {
      name: "Thailand",
      code: "TH",
      phone_code: "+66",
      flag: "https://flagcdn.com/th.svg",
    },
    {
      name: "Timor-Leste",
      code: "TL",
      phone_code: "+670",
      flag: "https://flagcdn.com/tl.svg",
    },
    {
      name: "Togo",
      code: "TG",
      phone_code: "+228",
      flag: "https://flagcdn.com/tg.svg",
    },
    {
      name: "Tonga",
      code: "TO",
      phone_code: "+676",
      flag: "https://flagcdn.com/to.svg",
    },
    {
      name: "Trinidad and Tobago",
      code: "TT",
      phone_code: "+1-868",
      flag: "https://flagcdn.com/tt.svg",
    },
    {
      name: "Tunisia",
      code: "TN",
      phone_code: "+216",
      flag: "https://flagcdn.com/tn.svg",
    },
    {
      name: "Turkey",
      code: "TR",
      phone_code: "+90",
      flag: "https://flagcdn.com/tr.svg",
    },
    {
      name: "Turkmenistan",
      code: "TM",
      phone_code: "+993",
      flag: "https://flagcdn.com/tm.svg",
    },
    {
      name: "Tuvalu",
      code: "TV",
      phone_code: "+688",
      flag: "https://flagcdn.com/tv.svg",
    },
    {
      name: "Uganda",
      code: "UG",
      phone_code: "+256",
      flag: "https://flagcdn.com/ug.svg",
    },
    {
      name: "Ukraine",
      code: "UA",
      phone_code: "+380",
      flag: "https://flagcdn.com/ua.svg",
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      phone_code: "+971",
      flag: "https://flagcdn.com/ae.svg",
    },
    {
      name: "United Kingdom",
      code: "GB",
      phone_code: "+44",
      flag: "https://flagcdn.com/gb.svg",
    },
    {
      name: "United States",
      code: "US",
      phone_code: "+1",
      flag: "https://flagcdn.com/us.svg",
    },
    {
      name: "Uruguay",
      code: "UY",
      phone_code: "+598",
      flag: "https://flagcdn.com/uy.svg",
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      phone_code: "+998",
      flag: "https://flagcdn.com/uz.svg",
    },
    {
      name: "Vanuatu",
      code: "VU",
      phone_code: "+678",
      flag: "https://flagcdn.com/vu.svg",
    },
    {
      name: "Vatican City",
      code: "VA",
      phone_code: "+379",
      flag: "https://flagcdn.com/va.svg",
    },
    {
      name: "Venezuela",
      code: "VE",
      phone_code: "+58",
      flag: "https://flagcdn.com/ve.svg",
    },
    {
      name: "Vietnam",
      code: "VN",
      phone_code: "+84",
      flag: "https://flagcdn.com/vn.svg",
    },
    {
      name: "Yemen",
      code: "YE",
      phone_code: "+967",
      flag: "https://flagcdn.com/ye.svg",
    },
    {
      name: "Zambia",
      code: "ZM",
      phone_code: "+260",
      flag: "https://flagcdn.com/zm.svg",
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      phone_code: "+263",
      flag: "https://flagcdn.com/zw.svg",
    },
  ],
};

export default Country;

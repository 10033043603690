import React from "react";

import AWS from "../../assets/case-study/tools/aws.svg";
import AWSCloudFrontCDN from "../../assets/case-study/tools/aws-cloudfront-cdn.svg";
import AWSCloudFrontRouting from "../../assets/case-study/tools/aws-cloudfront-routing.svg";
import NodeJS from "../../assets/case-study/tools/nodejs.svg";
import ReactJS from "../../assets/case-study/tools/reactjs.svg";
import HTML from "../../assets/case-study/tools/html5.svg";
import CSS from "../../assets/case-study/tools/css3.svg";
import Flutter from "../../assets/case-study/tools/flutter.svg";
import EmailJS from "../../assets/case-study/tools/emailjs.svg";
import Selenium from "../../assets/case-study/tools/selenium.svg";
import Python from "../../assets/case-study/tools/python.svg";
import Separator from "../../assets/case-study/tools/separator.svg";

const Tools = () => {
  return (
    <div className="mt-8">
      <p className="text-white text-left text-[28px] sm:text-[24px] font-semibold">
        Tools
      </p>

      {/* Row 1 */}
      <div className="flex flex-wrap justify-evenly gap-8 sm:gap-4 md:gap-12 items-center mt-8">
        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">Cloud</p>
          <img src={AWS} alt="aws" className="w-[5.312rem] sm:w-[4rem]" />
          <p className="text-base sm:text-sm text-white font-semibold">AWS</p>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">CDN</p>
          <img
            src={AWSCloudFrontCDN}
            alt="aws-cloudfront-cdn"
            className="w-[5.312rem] sm:w-[4rem]"
          />
          <p className="text-base sm:text-sm text-white font-semibold">
            AWS Cloudfront
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">Routing</p>
          <img
            src={AWSCloudFrontRouting}
            alt="aws-cloudfront-routing"
            className="w-[5.312rem] sm:w-[4rem]"
          />
          <p className="text-base sm:text-sm text-white font-semibold">
            AWS Cloudfront
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center mt-6">
        <img src={Separator} alt="separator" />
      </div>

      {/* Row 2 */}
      <div className="flex flex-wrap justify-evenly gap-8 sm:gap-4 md:gap-12 items-center mt-8">
        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">Backend</p>
          <img src={NodeJS} alt="nodejs" className="w-[5.312rem] sm:w-[4rem]" />
          <p className="text-base sm:text-sm text-white font-semibold">
            Node Js
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">
            Frontend
          </p>
          <div className="flex flex-wrap justify-evenly gap-8 sm:gap-4">
            {/* ReactJS */}
            <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
              <img
                src={ReactJS}
                alt="reactjs"
                className="w-[5.312rem] sm:w-[4rem]"
              />
              <p className="text-base sm:text-sm text-white font-semibold">
                React Js
              </p>
            </div>

            {/* HTML */}
            <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
              <img src={HTML} alt="html" className="w-[5.312rem] sm:w-[4rem]" />
              <p className="text-base sm:text-sm text-white font-semibold">
                HTML
              </p>
            </div>

            {/* CSS */}
            <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
              <img src={CSS} alt="css" className="w-[5.312rem] sm:w-[4rem]" />
              <p className="text-base sm:text-sm text-white font-semibold">
                CSS
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">Mobile</p>
          <img
            src={Flutter}
            alt="flutter"
            className="w-[5.312rem] sm:w-[4rem]"
          />
          <p className="text-base sm:text-sm text-white font-semibold">
            Flutter
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center mt-6">
        <img src={Separator} alt="separator" />
      </div>

      {/* Row 3 */}
      <div className="flex flex-wrap justify-evenly gap-8 sm:gap-4 md:gap-12 items-center mt-8">
        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">Email</p>
          <img
            src={EmailJS}
            alt="emailjs"
            className="w-[5.312rem] sm:w-[4rem]"
          />
          <p className="text-base sm:text-sm text-white font-semibold">
            Email Js
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">Testing</p>
          <img
            src={Selenium}
            alt="selenium"
            className="w-[5.312rem] sm:w-[4rem]"
          />
          <p className="text-base sm:text-sm text-white font-semibold">
            Selenium
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 sm:gap-2">
          <p className="text-white text-xl sm:text-lg font-semibold">AI</p>
          <img src={Python} alt="python" className="w-[5.312rem] sm:w-[4rem]" />
          <p className="text-base sm:text-sm text-white font-semibold">
            Python
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center mt-6">
        <img src={Separator} alt="separator" />
      </div>
    </div>
  );
};

export default Tools;

import React, { useState } from "react";
import accordianUpArrow from "../../assets/icons/chevron-up.svg";
import accordianDownArrow from "../../assets/icons/chevron-down.svg";
import FAQ from "../../data/FAQ";

export default function Accordion({ isHome }) {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFAQ = (id) => {
    setActiveFAQ(activeFAQ === id ? null : id);
  };

  const displayedFAQs = isHome ? FAQ.slice(0, 5) : FAQ;

  return (
    <>
      <div className="transition-all">
        {displayedFAQs.map((faq) => (
          <div key={faq.id}>
            <div
              className="flex justify-between sm:flex-row gap-2 items-start sm:items-center cursor-pointer transition-all"
              onClick={() => toggleFAQ(faq.id)}
            >
              <h4 className="text-sm ml-0 text-white md:text-xl font-normal  text-left ">
                {faq.question}
              </h4>
              <div className="ml-0 sm:ml-auto">
                {activeFAQ === faq.id ? (
                  <img
                    className="h-3 w-3 md:w-4 md:h-4"
                    src={accordianUpArrow}
                    alt="chevron-up"
                    style={{ maxWidth: "none" }}
                  />
                ) : (
                  <img
                    className="h-3 w-3 md:h-4 md:w-4"
                    src={accordianDownArrow}
                    alt="chevron-down"
                    style={{ maxWidth: "none" }}
                  />
                )}
              </div>
            </div>
            <div
              className="text-xs md:text-lg mt-2 text-left font-normal transition-all text-neutral3 overflow-hidden"
              style={{
                maxHeight: activeFAQ === faq.id ? "200px" : "0",
                transition: "max-height 0.3s ease-in-out",
              }}
            >
              {faq.answer}
            </div>
            <div className="mt-5 mb-5 w-full h-px bg-separator"></div>
          </div>
        ))}
      </div>
    </>
  );
}

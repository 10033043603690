import Button from "../shared/Button";
import axios from "axios";
import { useEffect, useState } from "react";

export default function ContactUs() {
  const [callbackNumber, setCallbackNumber] = useState("");
  const [enquiryList, setEnquiryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitData, setSubmitData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    enquiryCategoryId: "4",
  });
  const [file, setFile] = useState(null);
  const requestCallback = async () => {
    if (callbackNumber.length < 10) {
      alert("Please enter a valid mobile number.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submitCallback`,
        { contactNumber: callbackNumber }
      );
      if (response.data.meta.status) {
        alert("Callback submitted successfully!");
        setCallbackNumber("");
      } else {
        alert(response.data.meta.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllEnquiryType = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getAllEnquiryCategory`,
        {}
      );
      if (response.data.meta.status) {
        setEnquiryList(response.data.data.categoryList);
      } else {
        alert("Unable to get enquiry category list.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitEnquiry = async () => {
    for (const key in submitData) {
      if (submitData[key] === "" || submitData[key] === null) {
        alert("Missing fields.");
        return;
      }
    }
    if (!file) {
      alert("Missing file.");
      return;
    }
    let fileUrl;
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    const fileResp = await axios.post(
      `${process.env.REACT_APP_API_URL}/uploadDocument`,
      formData
    );
    if (fileResp.data.meta.status) {
      fileUrl = fileResp.data.data.fileUrl;
    } else {
      alert("Cannot upload file!");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submitEnquiry`,
        { ...submitData, projectDescLink: fileUrl }
      );
      setLoading(false);
      if (response.data.meta.status) {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      } else {
        alert("Error requesting Quote.");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getAllEnquiryType();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="text-white about-us-gradient-right">
      <h1 className="text-[40px] font-semibold text-center text-white">
        Contact Us
      </h1>
      <p className="mt-2 text-lg font-normal mb-6">
        Start Your Digital Journey Today - Have questions or need assistance?
        Our dedicated business development team is here to help. Reach out to us
        and kickstart your journey to success
      </p>
      <form className="flex flex-col lg:flex-row flex-wrap justify-between about-us-gradient-left">
        <div className="flex flex-col w-full lg:w-1/2 p-2 text-left gap-y-2">
          <div>
            <label
              htmlFor="name"
              className="block font-normal text-[18px] my-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={submitData.name}
              onChange={(e) => {
                setSubmitData((p) => ({ ...p, name: e.target.value }));
              }}
              placeholder="Enter your full name"
              className=" p-4 h-[60px] w-full lg:w-[515px] block  rounded-md bg-brandBackground border-[#474747]"
            />
          </div>
          <div>
            <label
              htmlFor="contact"
              className="block font-normal text-[18px] my-2"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="contact"
              value={submitData.contactNumber}
              onChange={(e) => {
                setSubmitData((p) => ({ ...p, contactNumber: e.target.value }));
              }}
              placeholder="Enter your Phone Number"
              className=" p-4 h-[60px] w-full lg:w-[515px] block  rounded-md bg-brandBackground border-[#474747]"
            />
          </div>
          <div>
            <label
              htmlFor="enquiry"
              className="block font-normal text-[18px] my-2"
            >
              Enquiry Type
            </label>
            <select
              id="enquiry"
              className="block  p-4 h-[60px] w-full lg:w-[515px] rounded-md bg-brandBackground border-[#474747]"
              value={submitData.enquiryCategoryId}
              onChange={(e) => {
                setSubmitData((p) => ({
                  ...p,
                  enquiryCategoryId: e.target.value,
                }));
              }}
            >
              {enquiryList?.map((enquiry) => (
                <option
                  key={enquiry.enquiryCategoryId}
                  value={enquiry.enquiryCategoryId}
                >
                  {enquiry.enquiryCategoryName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-1/2 p-2 justify-between text-left">
          <div className="flex flex-col justify-start gap-2">
            <div>
              <label
                htmlFor="email"
                className="block font-normal text-[18px] my-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Your business/communication email"
                className="p-4 h-[60px] w-full lg:w-[515px] block rounded-md bg-brandBackground border-[#474747]"
                value={submitData.email}
                onChange={(e) => {
                  setSubmitData((p) => ({ ...p, email: e.target.value }));
                }}
              />
            </div>
            <div>
              <label
                htmlFor="project"
                className="block font-normal text-[18px] my-2"
              >
                Project Description (PDF/Text)
              </label>
              <input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                id="project"
                placeholder="What do you need?"
                className=" p-4 h-[60px] w-full lg:w-[515px] block rounded-md bg-brandBackground border-[#474747]"
              />
            </div>
          </div>
          {isSubmitted ? (
            <span className="text-white">Thank you for submitting!</span>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                submitEnquiry();
              }}
              loading={loading}
              buttonText="Request a Quote"
              className=" h-14 w-[515px] mt-8"
            />
          )}
        </div>

        <hr className="w-full mt-10" />
        <div className="p-2">
          <div className="my-10">
            <label
              htmlFor="urgent"
              className="block font-normal text-[18px] my-2 text-left"
            >
              Is it urgent?
            </label>
            <input
              type="text"
              id="urgent"
              placeholder="+91 Enter your mobile number"
              className="p-4 h-[60px] w-full lg:w-[515px] block rounded-md bg-brandBackground border-[#474747]"
              maxLength={15}
              value={callbackNumber}
              onChange={(e) => {
                setCallbackNumber(e.target.value);
              }}
            />
          </div>
          <div className="my-10 flex justify-start">
            <Button
              buttonText="Request a call back"
              className="h-14"
              onClick={(e) => {
                e.preventDefault();
                requestCallback();
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

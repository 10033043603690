import { useEffect } from "react";
import PrivacyPolicy from "../components/privacy-policy/PrivacyPolicy";
import { Helmet } from "react-helmet";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Privacy Policy</title>
        <meta
          name="description"
          content="Review our Privacy Policy: Learn how we collect, use, and protect your personal information."
        />
      </Helmet>
      <PrivacyPolicy />
    </>
  );
};

export default PrivacyPolicyPage;

import React, { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
const CountryDropdown = ({ countries, selectedCountry, onChange }) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className="relative">
      <button
        className="relative h-[60px] flex items-center space-x-2  px-2 py-2 rounded-md text-white bg-gray-300 bg-opacity-20 border border-neutral3 focus:outline-none focus:ring-2  "
        onClick={() => setShowOptions(!showOptions)}
      >
        <img
          src={selectedCountry.flag}
          alt={selectedCountry.name}
          className="w-[30px] h-[30px]"
        />
        <div className="">{selectedCountry.phone_code}</div>

        <RiArrowDownSLine size={20} />
      </button>
      {showOptions && (
        <div className="absolute h-[160px] hide-scrollbar text-white overflow-y-auto  w-full rounded-md bg-gray-700 bg-opacity-90 border border-neutral3">
          {countries.map((country) => (
            <div
              key={country.code}
              className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-600 hover:bg-opacity-80"
              value={country.code}
              onClick={() => {
                onChange(country.code);
                setShowOptions(false);
              }}
            >
              <img
                src={country.flag}
                alt={country.name}
                className="w-[25px] h-[25px]"
              />
              <span className="ml-1">{country.phone_code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;

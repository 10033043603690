import BlogsMain from "../components/blogs/BlogsMain";
import { Helmet } from "react-helmet";

export default function BlogsPage() {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Blogs</title>
        <meta
          name="description"
          content="Stay updated with our blog: Insights on custom software, web, and mobile app development. Explore IT trends and innovations."
        />
      </Helmet>
      <BlogsMain />
    </>
  );
}

import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import BackButton from "../shared/BackButton";
import Button from "../shared/Button";
import Planner from "../case-study/Planner";
import Blogs from "../shared/Blogs";
import Tools from "../../components/case-study/Tools";
import CaseStudiesDetails from "../../data/CaseStudiesDetails";
import { Helmet } from "react-helmet";

const CaseStudyDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { id } = useParams();

  // const filteredCaseStudyData = CaseStudiesDetails[id - 1];
  const filteredCaseStudyData=CaseStudiesDetails.find(study=>study.id==id);

  return (
    <div>
      <Helmet>
        <title>Visibla Solutions | Case Studies</title>
        <meta
          name="description"
          content="IT solutions tailored to diverse industries. We serve a wide range of industries by providing custom software development solutions which meet the specific structure."
        />
      </Helmet>
      <BackButton className="mt-8" navigateTo="/case-study" />
      <div>
        <section className="relative flex w-full mt-10">
          <img
            src={filteredCaseStudyData.image}
            alt="hero"
            className="md:w-full md:h-auto opacity-20"
          />
          <div className="mt-20 absolute inset-0 flex flex-col w-full mx-auto justify-center items-center text-overlay">
            <h1 className="text-white text-[20px] text-center font-semibold md:text-[40px]">
              {filteredCaseStudyData.primaryTitle}
            </h1>
            <p className="text-white text-base mt-4 text-center font-normal md:text-2xl">
              {filteredCaseStudyData.secondaryTitle}
            </p>
            <Link to="/contact-us">
              <Button buttonText="Get a Quote" className="text-center mt-4" />
            </Link>
          </div>
        </section>

        <div className="mt-24 md:mt-14">
          <p className="text-white text-left text-xs md:text-lg">
            {filteredCaseStudyData.primaryParagraph}
          </p>

          <p className="mt-4 text-white text-left text-xs md:text-lg">
            {filteredCaseStudyData.secondaryParagraph}
          </p>

          <p className="mt-4 text-white text-left text-xs md:text-lg">
            {filteredCaseStudyData.tertiaryParagraph}
          </p>
        </div>

        <div className="flex flex-col md:flex md:flex-row gap-4 mt-8">
          <div className="basis-1/3 bg-gray18 p-6 md:w-[404px] md:h-[536px]">
            <h1 className="text-white text-sm md:text-[28px] font-semibold">
              {filteredCaseStudyData.initialCardTitle}
            </h1>

            <p className="text-xs md:text-lg font-medium text-white text-left mt-8">
              {filteredCaseStudyData.initialCard}
            </p>
          </div>

          <div className="basis-1/3 bg-gray18 p-6 md:w-[404px] md:h-[536px]">
            <h1 className="text-white text-sm md:text-[28px] font-semibold">
              {filteredCaseStudyData.centerCardTitle}
            </h1>

            <p className="text-xs md:text-lg font-medium text-white text-left mt-8">
              {filteredCaseStudyData.centerCard}
            </p>
          </div>

          <div className="basis-1/3 bg-tertiary p-6 md:w-[404px] md:h-[536px]">
            <h1 className="text-white text-sm md:text-[28px] font-semibold">
              {filteredCaseStudyData.finalCardTitle}
            </h1>

            <p className="text-xs md:text-lg font-medium text-white text-left mt-8">
              {filteredCaseStudyData.finalCard}
            </p>
          </div>
        </div>
        <div className="">
          <h1 className="text-white text-sm md:text-[28px] text-left font-semibold mt-4">
            Values Delivered
          </h1>
          <div className="flex flex-col md:flex-row text-left gap-2 mt-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {filteredCaseStudyData.Values.map((value) => {
                return (
                  <div key={value.title}>
                    <p className="text-secondary border-l border-l-secondary pl-4 pb-4 mt-3 text-sm sm:text-lg md:text-2xl lg:text-[28px]">
                      {value.title}
                    </p>
                    <div className="border-l border-l-darkGray pl-4">
                      <ul className="text-white">
                        <li className="text-white text-xs md:text-base">
                          {value.pointOne}
                        </li>
                        <li className="text-white text-xs md:text-base mt-2">
                          {value.pointTwo}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Planner />
      <Tools />
      <div className="mt-8">
        <Blogs Blogtitle={"Read more case studies like this"} />
      </div>
    </div>
  );
};

export default CaseStudyDetails;

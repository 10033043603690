import React, { useEffect, useRef, useState } from "react";
import SectionTitle from "../shared/SectionTitle";
import { Link, useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import Services from "../../data/Services";
import Separator from "../../assets/services/separator.svg";

import ScrollToTop from "../shared/ScrollToTop";

const ServicesMain = () => {
  const serviceRefs = useRef([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const keyWords = [
    "Custom Software Development",
    "Web App Development",
    "Cloud & DevOps",
    "Mobile App Development",
    "Software Testing",
    "AI and ML services",
    "Staff Augmentation",
    "Block Chain Solutions",
    "Digital Branding and Marketing",
    "Talent Resourcing",
    "Consulting",
    "Master Data Migration",
    "Data Warehousing & Business Intelligence",
    "Legacy Systems Maintenance & Migration",
    "Enterprise Application Integration",
    "Engineering and Technical Staffing",
    "Professional Staffing",
  ];

  const handleKeywordClick = (index) => {
    serviceRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="mb-10 px-2 md:px-8">
      <SectionTitle sectionText="Services" className="flex justify-center" />

      <h1 className="font-semibold text-[32px] md:text-[40px] mt-4 text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
        Build, Optimize, Grow
      </h1>
      <p className="mt-4 text-white text-center">
        From custom software to talent sourcing, we deliver quality - fast.
      </p>
      <Button
        onClick={() => {
          navigate("/contact-us");
        }}
        buttonText="Opt for a Discovery Call"
        className="mt-4"
      />

      <div className="flex flex-wrap gap-4 my-4 mt-12 md:mt-24 justify-center">
        {keyWords.map((keyword, index) => {
          return (
            <button
              key={index}
              className="text-[14px] md:text-base px-4 md:px-8 py-2 md:py-3 rounded-full border border-gray18 bg-footerBackground text-neutral3 hover:text-white hover:bg-transparent hover:bg-primary hover:border-primary"
              onClick={() => handleKeywordClick(index)}
            >
              {keyword}
            </button>
          );
        })}
      </div>
      {Services.map((service, index) => {
        return (
          <div
            key={service.id}
            className="mt-12"
            ref={(el) => (serviceRefs.current[index] = el)}
          >
            <div className="flex flex-col md:flex-row justify-between items-center mt-4">
              <div className="md:w-1/2">
                <div className="md:w-[510px] h-[88px]">
                  <h1 className="text-white font-semibold text-2xl md:text-4xl text-left">
                    {service.title}
                  </h1>
                  <p className="mt-4 text-white text-medium text-lg text-left">
                    {service.caption}
                  </p>
                </div>
                <div className="flex flex-wrap justify-start items-center gap-3 mt-28 md:mt-20">
                  {service.techStack.map((tech, index) => {
                    return (
                      <SectionTitle
                        key={index}
                        sectionText={tech}
                        innerDivStyle="w-[100px] md:w-[140px]"
                      />
                    );
                  })}
                </div>
                <Link to={`/services/${service.id}`}>
                  <Button buttonText="See details" className="mt-6 text-left" />
                </Link>
              </div>
              <div className="mt-6 md:mt-0 md:w-1/2">
                <img
                  src={service.image}
                  alt={service.name}
                  className="w-full"
                />
              </div>
            </div>
            <div className="mt-8 flex flex-col md:flex-row items-start justify-evenly gap-4 md:gap-0">
              {service.results.map((result, index) => {
                return (
                  <div
                    key={index}
                    className="text-left gap-2 mt-4 basis-full md:basis-1/3"
                  >
                    <div className="border-l border-l-neutral3 pl-4">
                      <p className="text-xl text-secondary">{result.value}</p>
                      <p className="text-white mt-6">{result.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-12">
              <img src={Separator} alt="Separator" className="w-full" />
            </div>
          </div>
        );
      })}

      {showScrollToTop && <ScrollToTop />}
    </div>
  );
};

export default ServicesMain;

import BlogDetails from "../components/blog-details/BlogDetails";
import { Helmet } from "react-helmet";

export default function BlogDetailsPage() {
  return (
    <div>
      <Helmet>
        <title>Visibla Solutions | Blogs</title>
        <meta
          name="description"
          content="Stay updated with our blog: Insights on custom software, web, and mobile app development. Explore IT trends and innovations."
        />
      </Helmet>
      <BlogDetails />
    </div>
  );
}

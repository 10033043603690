import React from "react";
import CaseStudyMain from "../components/case-study/CaseStudyMain.jsx";
import { Helmet } from "react-helmet";

const CaseStudyPage = () => {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | Case Studies</title>
        <meta
          name="description"
          content="IT solutions tailored to diverse industries. We serve a wide range of industries by providing custom software development solutions which meet the specific structure."
        />
      </Helmet>
      <CaseStudyMain />
    </>
  );
};

export default CaseStudyPage;

import Planner from "../assets/case-study/case-study-planner.svg";

import FreezeBooking from "../assets/case-study/background-images/freezebooking.svg";
import Logissy from "../assets/case-study/background-images/logissy.svg";
import FoodCommerceApp from "../assets/case-study/background-images/food-commerce-app.svg";
import ParkingApp from "../assets/case-study/background-images/parking-app.svg";
import VehicleTransitSolutions from "../assets/case-study/background-images/vehicle-transit-solutions.svg";
import ResidentTransportSolutions from "../assets/case-study/background-images/resident-transport-solutions.svg";

const CaseStudiesDetails = [
  {
    id: 'freeze-booking',
    primaryTitle: "Transforming Travel Experiences:",
    image: FreezeBooking,
    secondaryTitle:
      "Streamlining Booking Processes and Driving Customer Delight in the Digital Age",
    primaryParagraph:
      "Simplifying the booking process and capturing customer involvement are critical in the fast-paced world of internet travel. Imagine being able to quickly and easily navigate through a vast array of travel possibilities on your mobile with a few touches. That is the exact goal that motivates our Online Travel Booking Platform initiative.",
    secondaryParagraph:
      "Our group faced the difficult task of analysing intricate booking processes while stimulating user curiosity. We created user-friendly UI/UX designs and leveraged the power of cutting-edge technology to address this. What was the outcome? a website that not only makes it easier to find and reserve hotels, flights, buses, and vacation packages, but also uses intelligent recommendations to make your trip more unique.",
    tertiaryParagraph:
      "After implementing our solution, consumer satisfaction reached unprecedented levels, which helped our client take the lead in the competitive travel sector. Our project stands as a testament to our ability to transform obstacles into opportunities, driving both business growth and customer delight in the digital age.",
    initialCardTitle: "The Problem",
    initialCard:
      "The challenge faced was the complexity of booking processes and the need to enhance user engagement in the online travel booking industry. This suggests that users were likely finding it cumbersome to navigate through various options and complete bookings, leading to potential frustration and disengagement.",
    centerCardTitle: "The Solution",
    centerCard:
      "The team addressed this challenge by leveraging cutting-edge technology and implementing intuitive UI/UX designs. By doing so, they simplified the booking processes and made the platform more user-friendly. Additionally, the implementation of intelligent recommendations personalized user experiences, making the platform more engaging and relevant to individual users.",
    finalCardTitle: "The Impact",
    finalCard:
      "The described solution significantly improved customer satisfaction, indicating that users found the platform easier to use and more enjoyable overall. Moreover, it propelled the client to the forefront of the competitive travel industry, suggesting that the improvements made gave them a competitive edge over other players in the market. Overall, the project exemplifies the team's ability to turn challenges into opportunities, driving both business growth and customer delight in the digital age.",
    plannerImage: Planner,
    Values: [
      {
        title: "Streamlining Logistics Operations",
        pointOne:
          "Developed user-friendly interfaces to streamline the search and booking of travel options.",
        pointTwo:
          "Implemented intuitive navigation to eliminate confusion and reduce the time required for bookings.",
      },
      {
        title: "Enhancing User Experience",
        pointOne:
          "Leveraged cutting-edge technology to personalize user experiences with intelligent recommendations.",
        pointTwo:
          "Incorporated interactive features to captivate user interest and encourage exploration of available travel options.",
      },
      {
        title: "Technological Innovations",
        pointOne:
          "Integrated state-of-the-art algorithms to automate booking processes and ensure efficient handling of user requests.",
        pointTwo:
          "Employed advanced analytics to gather insights into user preferences and behavior, enabling continuous improvement of the platform.",
      },
      {
        title: "Improving Customer Satisfaction",
        pointOne:
          "Conducted user feedback sessions to identify pain points and areas for improvement, leading to iterative enhancements of the platform.",
        pointTwo:
          "Implemented customer-centric design principles to prioritize user satisfaction and exceed their expectations.",
      },
      {
        title: "Establishing Competitive Edge",
        pointOne:
          "Analyzed market trends and competitor offerings to identify opportunities for differentiation and innovation.",
        pointTwo:
          "Positioned the client as a potential leader in the travel industry by delivering a solution that surpasses market standards and meets evolving customer needs.",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Executed targeted marketing campaigns to increase brand awareness and attract new users to the platform.",
        pointTwo:
          "Explored strategic partnerships and collaborations to expand the platform's reach and drive revenue growth.",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Established a culture of continuous learning and innovation within the team to stay ahead of industry trends and emerging technologies.",
        pointTwo:
          "Encouraged cross-functional collaboration and knowledge sharing to leverage collective expertise and drive ongoing improvements to the platform.",
      },
    ],
  },

  {
    id: 'logissy',
    primaryTitle: "Charting New Waters",
    image: Logissy,
    secondaryTitle:
      "Revolutionizing Maritime Logistics with an Advanced Booking Platform for Ships",
    primaryParagraph:
      "In our exploration of the 'Logistics Booking Platform for Ships' case study, we detail our endeavor to revolutionize the maritime shipping industry. Confronted with the intricacies of managing logistics and optimizing ship booking processes, our team embarked on the journey to develop a robust platform integrating advanced AI algorithms and real-time data analytics.",
    secondaryParagraph:
      "This platform serves as a cornerstone for efficient route planning, cargo management, and cost optimization, providing a seamless booking experience for shipping companies and cargo handlers alike. The implementation of this solution has ushered in unparalleled operational efficiency, notable reductions in overhead costs, and a heightened competitive edge for our client.",
    tertiaryParagraph:
      "This case study serves as a testament to our capability to navigate complex logistical challenges and deliver impactful technological advancements in the logistics sector. Just as in the fast-paced world of online travel, where simplifying the booking process and captivating user engagement are paramount, our project exemplifies our dedication to driving both efficiency and excellence in the maritime shipping industry.",
    initialCardTitle: "The Problem",
    initialCard:
      "Confronted with the complexities of managing logistics and optimizing ship booking processes in the maritime shipping industry, a significant challenge emerged. The intricacies of these procedures posed hurdles, potentially hindering operational efficiency and impeding user engagement. It suggested that users might struggle to navigate through the plethora of options available, leading to frustration and disengagement.",
    centerCardTitle: "The Solution",
    centerCard:
      "To overcome this challenge, our team employed a multifaceted approach. Leveraging advanced AI algorithms and real-time data analytics, we developed a robust platform aimed at streamlining logistics management and enhancing the ship booking process. Implementing intuitive UI/UX designs, we focused on simplifying procedures, making the platform more user-friendly. ",
    finalCardTitle: "The Impact",
    finalCard:
      "The implementation of our solution yielded significant outcomes. Notably, it substantially improved operational efficiency and reduced overhead costs, marking a notable shift in the maritime shipping industry's landscape. Users reported enhanced satisfaction, finding the platform easier to navigate and more enjoyable overall. Furthermore, our client's position was bolstered, propelling them to the forefront of the competitive landscape.",
    plannerImage: Planner,
    Values: [
      {
        title: "Streamlining Logistics Operations",
        pointOne:
          "Developed user-friendly interfaces to simplify the process of route planning and cargo booking.",
        pointTwo:
          "Implemented intuitive navigation to enhance efficiency and reduce the time required for bookings.",
      },
      {
        title: "Enhancing User Experience",
        pointOne:
          "Leveraged advanced AI algorithms to personalize user experiences with tailored route recommendations.",
        pointTwo:
          "Incorporated interactive features to engage users and encourage exploration of available options.",
      },
      {
        title: "Technological Innovations",
        pointOne:
          "Integrated cutting-edge algorithms to automate booking processes and ensure swift handling of requests.",
        pointTwo:
          "Utilized advanced analytics to gain insights into user preferences, driving continuous platform improvement.",
      },
      {
        title: "Improving Customer Satisfaction",
        pointOne:
          "Conducted user feedback sessions to pinpoint pain points and areas for enhancement.",
        pointTwo:
          "Applied customer-centric design principles to prioritize satisfaction and exceed expectations.",
      },
      {
        title: "Establishing Competitive Edge",
        pointOne:
          "Analyzed market trends to identify opportunities for differentiation and innovation.",
        pointTwo:
          "Positioned the client as an industry leader by delivering a solution that exceeds market standards and adapts to evolving customer needs.",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Established a culture of innovation and learning within the team to stay ahead of industry trends.",
        pointTwo:
          "Encouraged cross-functional collaboration and knowledge sharing to leverage collective expertise and drive ongoing platform enhancements.",
      },
    ],
  },

  {
    id: 'food-commerce-app',
    primaryTitle: "Redefining Food Commerce Experiences",
    image: FoodCommerceApp,
    secondaryTitle:
      "Using an Innovative Online Marketplace to Transform the Food Industry",
    primaryParagraph:
      "Through the review of the 'Kai Tuttu Chef' application, we reveal our goal of revolutionising the food market. Our team set out to create an innovative approach after being faced with the difficulty of enabling smooth meal ordering experiences in the context of home cooking.",
    secondaryParagraph:
      "Our platform empowers registered home chefs with advanced features such as food menu posting, order management, inventory tracking, and payment facilitation. Additionally, we designed a robust verification system to onboard new home chefs onto the Kai Tuttu portal.",
    tertiaryParagraph:
      "This platform stands as a testament to efficiency and excellence in culinary service delivery, providing a streamlined and intuitive experience for both chefs and customers alike. The implementation of this solution has marked significant advancements in culinary accessibility and efficiency, showcasing our dedication to driving innovation in the food industry. Just as in the fast-paced world of online travel, where simplicity and user engagement are paramount, our project underscores our commitment to delivering exceptional culinary experiences.",
    initialCardTitle: "The Problem",
    initialCard:
      "Our development team faced the challenging task of managing the complexities involved in providing smooth culinary experiences when creating the Kai Tuttu Chef application. Significant issues were posed in managing a wide range of food orders and culinary operations, which could have a negative impact on user engagement and operational efficiency. These difficulties suggested that users would be frustrated and disengaged as they struggled to navigate through the many menu choices.",
    centerCardTitle: "The Solution",
    centerCard:
      "To tackle these challenges, our team devised a thorough strategy. By utilising the latest technologies, such as strong eCommerce and retail solutions, we designed a flexible onboarding platform for home cooks on the Kai Tuttu web. Chefs can post menus and take customer orders with ease thanks to a simplified verification process. To improve operational effectiveness and customer happiness, we also included capabilities for order monitoring, inventory management, and payment facilitation.",
    finalCardTitle: "The Impact",
    finalCard:
      "The results of applying our solution were remarkable. It marked a noteworthy shift in the culinary setting by greatly increasing operational efficiency and streamlining cooking operations. Users expressed increased satisfaction, saying the platform was more engaging and simpler to use overall. In addition, our client's standing increased dramatically, positioning them as competitors in the intense culinary sector.",
    plannerImage: Planner,
    Values: [
      {
        title: "Optimizing Culinary Operations",
        pointOne:
          "Created intuitive interfaces simplifying food order management and menu posting for home chefs.",
        pointTwo:
          "Implemented user-friendly navigation to enhance efficiency and streamline food-related interactions.",
      },
      {
        title: "Enhancing User Experience",
        pointOne:
          "Utilized advanced algorithms to customize food ordering experiences with personalized recommendations.",
        pointTwo:
          "Integrated interactive features fostering customer engagement and facilitating exploration of available culinary options.",
      },
      {
        title: "Technological Innovations",
        pointOne:
          "Integrated cutting-edge algorithms automating food order processes and ensuring prompt handling of customer requests.",
        pointTwo:
          "Utilized advanced analytics to gain insights into customer preferences, facilitating continuous platform improvement.",
      },
      {
        title: "Improving Customer Satisfaction",
        pointOne:
          "Conducted feedback sessions to pinpoint pain points and areas for enhancement in culinary delivery.",
        pointTwo:
          "Applied customer-centric design principles prioritizing customer satisfaction and exceeding expectations.",
      },
      {
        title: "Establishing Competitive Edge",
        pointOne:
          "Analyzed culinary trends to identify opportunities for differentiation and innovation.",
        pointTwo:
          "Positioned the client as an industry leader by delivering a solution that exceeds market standards and adapts to evolving customer needs.",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Cultivated a culture of innovation and learning within the team to stay abreast of culinary trends.",
        pointTwo:
          "Encouraged cross-functional collaboration and knowledge sharing to leverage collective expertise and drive ongoing platform enhancements in the culinary domain.",
      },
    ],
  },

  {
    id: 'parking-app',
    primaryTitle: "Revolutionizing Urban Parking & Streamlining Mobility",
    image: ParkingApp,
    secondaryTitle:
      "Simplifying Urban Parking with Innovative Mobile Solutions",
    primaryParagraph:
      "Our goal is to transform parking experiences and urban mobility through our partnership on the client's parking solution. When faced with the difficulty of locating convenient parking in crowded city centers or popular tourist attractions, our team set out to provide a straightforward but efficient solution.",
    secondaryParagraph:
      "With the help of our app, you can easily reserve the ideal parking space by viewing all available places in the area on your phone. The Client's solution makes it simple to identify and reserve parking, whether you're traveling or living in a city, so your travel plans will be optimized.",
    tertiaryParagraph:
      "This technology, which gives customers access to various parking options in real-time, is a structure to the effectiveness and ease of parking solutions. The Client's implementation includes capabilities for travel planning, booking procedures that are smooth, and an intuitive interface design that makes it easy for users to browse cities and tourist destinations. This demonstrates our dedication to promoting innovation in urban mobility by giving accessibility and convenience in parking options top priority.",
    initialCardTitle: "The Problem",
    initialCard:
      "In crowded city centers or popular tourist locations, it can be difficult and time-consuming to find convenient parking. The scarcity of parking spaces and the trouble of driving about looking for one can cause tension and discomfort whether you live in a city or are visiting one.",
    centerCardTitle: "The Solution",
    centerCard:
      "Our team created an intuitive smartphone application that transforms parking experiences and urban mobility, in response to this dilemma. The Client's solution makes it simple for users to locate and book the ideal location without difficulty by displaying all nearby parking spaces on their phone. Users may maximize their travel schedules and save themselves the hassle of looking for parking by using The Client's solution.",
    finalCardTitle: "The Impact",
    finalCard:
      "The ease of parking and urban transportation have significantly improved since The Client's solution was implemented. With real-time access to available parking alternatives, users may now save time and reduce stress. The Client's user-friendly design, easy-to-use booking procedures, and travel planning tools have made parking easier for both city inhabitants and tourists, proving our dedication to promoting innovation in urban mobility and improving user convenience.",
    plannerImage: Planner,
    Values: [
      {
        title: "Optimizing Culinary Operations",
        pointOne:
          "Developed a user-friendly mobile app that displays all nearby parking spots on users' phones, facilitating hassle-free booking and optimizing travel plans for city dwellers and vacationers alike.",
        pointTwo: "",
      },
      {
        title: "Enhancing User Experience",
        pointOne:
          "Implemented intuitive interface design and seamless booking processes within The Client's app, ensuring users can easily find and reserve parking with ease, enhancing overall convenience and satisfaction.",
        pointTwo: "",
      },
      {
        title: "Technological Innovations",
        pointOne:
          "Deployed cutting-edge technologies to provide real-time access to available parking options, leveraging advanced algorithms to streamline parking spot discovery and reservation processes, showcasing our commitment to innovation in urban mobility solutions.",
        pointTwo: "",
      },
      {
        title: "Improving Customer Satisfaction",
        pointOne:
          "Conducted regular feedback sessions to gather insights and address user concerns, prioritizing ease of use, reliability, and performance to exceed customer expectations and build trust in The Client's platform.",
        pointTwo: "",
      },
      {
        title: "Establishing Competitive Edge",
        pointOne:
          "Positioned The Client as a convenient and accessible parking solution, offering users seamless access to available parking options in urban areas and tourist spots, driving efficiency and enhancing the overall parking experience.",
        pointTwo: "",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Cultivated a culture of innovation and collaboration within our team to promote ongoing learning and development in urban mobility solutions, encouraging cross-functional knowledge sharing to drive continuous enhancements in parking accessibility and user experience.",
        pointTwo: "",
      },
    ],
  },

  {
    id: 'vehicle-transit-solutions',
    primaryTitle:
      "Revolutionizing Automotive Access & Streamlining Vehicle Imports",
    image: VehicleTransitSolutions,
    secondaryTitle: "Innovative Mobile Solutions",
    primaryParagraph:
      "We share our goal to reinvent the automotive experience for inhabitants through our partnership on the client's auto and vehicle solutions. When faced with the problem of giving building occupants easy access to a fleet of luxury vehicles, our team set out to find a creative solution.",
    secondaryParagraph:
      "With the help of our smartphone app, locals can easily make reservations and request their favorite vehicle for a specified time. The software arranges reservations, verifies them, and alerts personnel to the change in schedule so that the cars are ready.",
    tertiaryParagraph:
      "This platform offers inhabitants a simplified and easy-to-use experience, and it is a testament to the perfection and efficiency of auto and vehicle solutions. This solution's deployment has resulted in notable improvements in vehicle accessibility and convenience, demonstrating our commitment to fostering innovation in the real estate automotive sector. Similar to the rapidly evolving levels of internet travel, where user involvement and ease of use are critical, our project demonstrates our dedication to providing outstanding car experiences.",
    initialCardTitle: "The Problem",
    initialCard:
      "As we started to create trade software solutions, our team had to navigate the complicated field of vehicle imports, which was a daunting challenge. Significant challenges were posed in managing a wide range of importation procedures and regulatory regulations, which could hinder operational efficiency and user engagement. These difficulties alluded to possible inconveniences among consumers who are struggling with the complex steps involved in importing vehicles.",
    centerCardTitle: "The Solution",
    centerCard:
      "Our team developed a comprehensive plan based on modern techniques and cutting-edge technologies to overcome these obstacles. Specifically designed for US Registered Importers, our mobile software is multifunctional and has a built-in smartphone mobile bar and QR code scanner. Furthermore, we added features such as E-Manifest, Manufacturer Recall and title checks, user location geotagging, easy-to-use Manufacturer Label information data entry etc.",
    finalCardTitle: "The Impact",
    finalCard:
      "The practical use of our approach produced great results and significantly changed the market for trade software solutions. Improved operational efficiency led to a streamlined automobile importation procedure and higher levels of user satisfaction. In addition, our client's prominence increased, establishing them as pioneers in the competitive trade software sector.",
    plannerImage: Planner,
    Values: [
      {
        title: "Optimizing Culinary Operations",
        pointOne:
          "Crafted intuitive interfaces simplifying vehicle access management and documentation for the residents.",
        pointTwo:
          "Implemented user-friendly navigation to enhance efficiency and streamline the vehicle reservation process.",
      },
      {
        title: "Enhancing User Experience",
        pointOne:
          "Leveraged advanced algorithms to tailor vehicle access experiences with personalized recommendations.",
        pointTwo:
          "Integrated interactive features fostering user engagement and facilitating exploration of available vehicle options.",
      },
      {
        title: "Technological Innovations",
        pointOne:
          "Integrated cutting-edge algorithms automating vehicle access processes and ensuring swift handling of reservation requests.",
        pointTwo:
          "Utilized advanced analytics to gain insights into user preferences, driving continuous platform enhancement.",
      },
      {
        title: "Improving Customer Satisfaction",
        pointOne:
          "Conducted feedback sessions to identify pain points and areas for improvement in vehicle access processes.",
        pointTwo:
          "Applied user-centric design principles prioritizing user satisfaction and exceeding expectations.",
      },
      {
        title: "Establishing Competitive Edge",
        pointOne:
          "Analyzed automotive trends to identify opportunities for differentiation and innovation.",
        pointTwo:
          "Positioned the client as a leader in the automotive industry by delivering a solution that surpasses industry standards and adapts to evolving user needs.",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Cultivated a culture of innovation and learning within the team to stay ahead of automotive trends.",
        pointTwo:
          "Encouraged cross-functional collaboration and knowledge sharing to leverage collective expertise and drive ongoing platform enhancements in the automotive domain.",
      },
    ],
  },

  {
    id: 'resident-transport-solutions',
    primaryTitle: "Driving Luxury Automotive Access",
    image: ResidentTransportSolutions,
    secondaryTitle: "Innovative Resident Solutions",
    primaryParagraph:
      "We share our goal to reinvent the automotive experience for inhabitants through our partnership on the auto and vehicle solutions project.Our team set out to come up with a novel solution to the issue of providing building inhabitants with quick access to a fleet of premium cars.",
    secondaryParagraph:
      "Residents can easily make reservations and request their chosen car for a specific time with our smartphone app. To ensure that the cars are available, the programme makes reservations, confirms them, and notifies staff of any timetable changes.",
    tertiaryParagraph:
      "This platform offers residents a simplified and easy-to-use experience, and it is a monument to the perfection and efficiency of auto and vehicle solutions.The implementation of this solution has significantly increased the accessibility and convenience of vehicles, proving our dedication to promoting innovation in the real estate automotive industry. Our effort highlights our commitment to creating exceptional driving experiences, much as the quickly changing levels of internet travel, where user interaction and ease of use are key.",
    initialCardTitle: "The Problem",
    initialCard:
      "Our team faced a significant hurdle as we began to develop custom trade software solutions: navigating the convoluted world of automobile imports.Several importing processes and regulatory requirements presented significant management problems that might impede user engagement and operational efficiency.These issues hinted at potential annoyances for customers who are having trouble navigating the intricate procedures involved in importing automobiles.",
    centerCardTitle: "The Solution",
    centerCard:
      "To get over these challenges, our team created a thorough plan based on contemporary methods and cutting-edge technologies. Our multifunctional mobile programme, which is specifically made for US Registered Importers, has a built-in smartphone mobile bar and QR code VIN scanner. Additionally, we integrated Customs Broker for processing 7501, introduced ACE E-Manifest, Manufacturer Recall and title checks through Carfax, geotagged user locations and etc.",
    finalCardTitle: "The Impact",
    finalCard:
      "Our strategy was put to good use in the real world, changing the trade software solutions market and yielding excellent outcomes.Higher levels of user satisfaction and a more efficient automotive importation process were the results of improved operational efficiency.Furthermore, the company gained more recognition and became recognised as leaders in the competitive trade software industry.",
    plannerImage: Planner,
    Values: [
      {
        title: "Optimizing Culinary Operations",
        pointOne:
          "Crafted intuitive interfaces simplifying vehicle access management and documentation for the residents.",
        pointTwo:
          "Implemented user-friendly navigation to enhance efficiency and streamline the vehicle reservation process.",
      },
      {
        title: "Enhancing User Experience",
        pointOne:
          "Leveraged advanced algorithms to tailor vehicle access experiences with personalized recommendations.",
        pointTwo:
          "Integrated interactive features fostering user engagement and facilitating exploration of available vehicle options.",
      },
      {
        title: "Technological Innovations",
        pointOne:
          "Integrated cutting-edge algorithms automating vehicle access processes and ensuring swift handling of reservation requests.",
        pointTwo:
          "Utilized advanced analytics to gain insights into user preferences, driving continuous platform enhancement.",
      },
      {
        title: "Improving Customer Satisfaction",
        pointOne:
          "Conducted feedback sessions to identify pain points and areas for improvement in vehicle access processes.",
        pointTwo:
          "Applied user-centric design principles prioritizing user satisfaction and exceeding expectations.",
      },
      {
        title: "Establishing Competitive Edge",
        pointOne:
          "Analyzed automotive trends to identify opportunities for differentiation and innovation.",
        pointTwo:
          "Positioned the client as a leader in the automotive industry by delivering a solution that surpasses industry standards and adapts to evolving user needs.",
      },
      {
        title: "Fostering Continuous Improvement",
        pointOne:
          "Cultivated a culture of innovation and learning within the team to stay ahead of automotive trends.",
        pointTwo:
          "Encouraged cross-functional collaboration and knowledge sharing to leverage collective expertise and drive ongoing platform enhancements in the automotive domain.",
      },
    ],
  },
];

export default CaseStudiesDetails;

import NewsMain from "../components/news/NewsMain";
import { Helmet } from "react-helmet";

export default function NewsPage() {
  return (
    <>
      <Helmet>
        <title>Visibla Solutions | News</title>
        <meta
          name="description"
          content="Stay informed with our news: Updates on IT development, custom software, web, and mobile app solutions. Explore the latest industry news."
        />
      </Helmet>
      <NewsMain />
    </>
  );
}

const faqContent = [
  {
    id: 1,
    question: "1. What is your level of industry experience?",
    answer:
      "We boast extensive industry experience, having successfully delivered tailored IT solutions across various sectors for many years.",
  },
  {
    id: 2,
    question:
      "2. How do you handle it when your clients are somewhat tech-savvy?",
    answer:
      "We adjust our communication and explanations to match the technical understanding of our clients, ensuring clarity and collaboration throughout the project.",
  },
  {
    id: 3,
    question: "3. Which area do you specialise in?",
    answer:
      "Our expertise spans a wide array of IT services, including UI/UX design, web development, app development, and IT consulting.",
  },
  {
    id: 4,
    question:
      "4. What kind of professionals will be working on my project in addition to developers?",
    answer:
      "Alongside our developers, you'll have access to dedicated project managers, designers, and quality assurance specialists to ensure a comprehensive approach to your project.",
  },
  {
    id: 5,
    question:
      "5. I have an existing website created by a different service provider. Can you assist me in updating it?",
    answer:
      "Absolutely, we offer website update and maintenance services to enhance and optimise your existing website.",
  },
  {
    id: 6,
    question: "6. Do I get to keep the rights to my website?",
    answer:
      "Yes, upon completion, you retain full ownership and rights to your website, including the source code and design.",
  },
  {
    id: 7,
    question: "7. Which technologies are you involved in?",
    answer:
      "We work with a range of technologies such as HTML5, CSS3, JavaScript, Python, Java, PHP, React, Angular, Node.js, and more.",
  },
  {
    id: 8,
    question: "8. Can I examine the code while it's being developed?",
    answer:
      "Yes, we offer transparency. You'll have access to view the codebase and progress through our development platforms.",
  },
  {
    id: 9,
    question:
      "9. Which platform is best for me to make my first mobile application?",
    answer:
      "The best platform depends on your app's requirements, audience, and budget. We'll help you select between native (iOS, Android), hybrid, or cross-platform frameworks like React Native.",
  },
  {
    id: 10,
    question:
      "10. What will happen to my app when the latest iOS and Android operating systems are released?",
    answer:
      "We provide maintenance and updates to ensure compatibility with the latest OS versions, keeping your app running smoothly.",
  },
  {
    id: 11,
    question: "11. What features must be included in my mobile app?",
    answer:
      "Features depend on your app's purpose, but common ones include user authentication, push notifications, in-app purchases, and social media integration.",
  },
  {
    id: 12,
    question: "12. How do you ensure security in mobile app projects?",
    answer:
      "We follow industry best practices, including data encryption, secure authentication, and regular security audits to safeguard your app and user data.",
  },
  {
    id: 13,
    question:
      "13. What is the price for your website development and design services?",
    answer:
      "Prices vary based on project scope. We offer competitive rates for custom website development and design tailored to your needs.",
  },
  {
    id: 14,
    question: "14. How long would it take you to create my website?",
    answer:
      "Project timelines vary based on complexity. We'll provide a detailed timeline during the project discovery phase.",
  },
  {
    id: 15,
    question:
      "15. Why is Visibla Solutions an established provider of website development services?",
    answer:
      "We're renowned for our client-centric approach, innovative solutions, and a track record of delivering exceptional websites that drive results.",
  },
  {
    id: 16,
    question:
      "16. Will I be allowed to update the website myself? After delivery, how do I handle things?",
    answer:
      "Yes, we provide training and guidance on managing and updating your website's content using user-friendly Content Management Systems (CMS) like WordPress or custom solutions.",
  },
  {
    id: 17,
    question:
      "17. Are there any other expenses to be expected on top of the fees for web development services?",
    answer:
      "The quoted price typically covers the agreed-upon services. Additional expenses might arise for hosting, domain registration, third-party plugins, or custom features.",
  },
  {
    id: 18,
    question: "18. My website needs to be updated. Could you please help?",
    answer:
      "Absolutely, we offer website update and maintenance services to keep your site fresh, secure, and up-to-date with the latest trends and technologies.",
  },
  {
    id: 19,
    question:
      "19. After the design process is over, who owns the rights to the website?",
    answer:
      "Upon completion and full payment, you own the rights to your website, including all design elements, code, and content.",
  },
  {
    id: 20,
    question:
      "20. Will you construct a website that is optimised for search engines?",
    answer:
      "Yes, we follow SEO best practices during development to ensure your website is search engine friendly and optimised for organic visibility.",
  },
  {
    id: 21,
    question:
      "21. Which developer level would you assign to my project, and how experienced would they be?",
    answer:
      "We carefully select developers based on project requirements. Rest assured, your project will be handled by skilled professionals with relevant experience.",
  },
  {
    id: 22,
    question: "22. What are your hiring models?",
    answer:
      "We offer various engagement models including dedicated teams, fixed-price projects, and hourly rates to suit your project needs.",
  },
  {
    id: 23,
    question: "23. What is the daily work hours of your developers?",
    answer:
      "Our developers typically work standard hours, but we can adjust schedules to accommodate different time zones or urgent project needs.",
  },
  {
    id: 24,
    question:
      "24. When I hire engineers from your organisation, will I have full access to them?",
    answer:
      "Yes, you'll have direct communication and collaboration with the assigned developers to ensure transparency and efficiency.",
  },
  {
    id: 25,
    question:
      "25. What steps do you take if a client expresses dissatisfaction with the work of one of your developers?",
    answer:
      "We have a structured feedback system and project managers to address any concerns promptly. We strive to resolve issues to your satisfaction.",
  },
  {
    id: 26,
    question: "26. What happens if one of your developers becomes ill?",
    answer:
      "We have a team-based approach. If a developer is unavailable, another team member with similar expertise will seamlessly step in to ensure project continuity.",
  },
];

export default faqContent;
